const letters =
[
  { guj: 'ક', eng: 'ka', hindi: 'क' },
  { guj: 'ખ', eng: 'kha', hindi: 'ख' },
  { guj: 'ગ', eng: 'ga', hindi: 'ग' },
  { guj: 'ઘ', eng: 'gha', hindi: 'घ' },
  { guj: 'ચ', eng: 'cha', hindi: 'च' },
  { guj: 'છ', eng: 'chha', hindi: 'छ' },
  { guj: 'જ', eng: 'ja', hindi: 'ज' },
  { guj: 'ઝ', eng: 'za', hindi: 'झ' },
  { guj: 'ટ', eng: 'ta', hindi: 'ट' },
  { guj: 'ઠ', eng: 'tha', hindi: 'ठ' },
  { guj: 'ડ', eng: 'da', hindi: 'ड' },
  { guj: 'ઢ', eng: 'dha', hindi: 'ढ' },
  { guj: 'ણ', eng: 'ana', hindi: 'ण' },
  { guj: 'ત', eng: 'ta', hindi: 'त' },
  { guj: 'થ', eng: 'tha', hindi: 'थ' },
  { guj: 'દ', eng: 'da', hindi: 'द' },
  { guj: 'ધ', eng: 'dha', hindi: 'ध' },
  { guj: 'ન', eng: 'na', hindi: 'न' },
  { guj: 'પ', eng: 'pa', hindi: 'प' },
  { guj: 'ફ', eng: 'fa', hindi: 'फ' },
  { guj: 'બ', eng: 'ba', hindi: 'ब' },
  { guj: 'ભ', eng: 'bha', hindi: 'भ' },
  { guj: 'મ', eng: 'ma', hindi: 'म' },
  { guj: 'ય', eng: 'ya', hindi: 'य' },
  { guj: 'ર', eng: 'ra', hindi: 'र' },
  { guj: 'લ', eng: 'la', hindi: 'ल' },
  { guj: 'વ', eng: 'va', hindi: 'व' },
  { guj: 'શ', eng: 'sha', hindi: 'श' },
  { guj: 'ષ', eng: 'shha', hindi: 'ष' },
  { guj: 'સ', eng: 'sa', hindi: 'स' },
  { guj: 'હ', eng: 'ha', hindi: 'ह' },
  { guj: 'ળ', eng: 'ala', hindi: 'ल' },
  { guj: 'ક્ષ', eng: 'ksha', hindi: 'क्ष', specialLetter: true },
  { guj: 'જ્ઞ', eng: 'gna', hindi: 'ज्ञ' },
  { guj: 'અ', eng: 'a', hindi: 'अ', specialLetter: true },
  { guj: 'ઋ', eng: 'ru', hindi: 'रू', specialLetter: true },
  { guj: 'શ્ર', eng: 'shra', hindi: 'श्र', specialLetter: true },
  { guj: 'ૐ', eng: 'ohm', hindi: 'ૐ', specialLetter: true }
]
export default letters
