import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: { gu: 'નીરુમાના સ્વપ્નમાં _ આવ્યા?' },
    options: 'કોણે,કોણ,કોઈ,ક્યાં',
    answers: { gu: 'કોણ' },
    highlights: [
      { statement: 'નીરુમાના સ્વપ્નમાં _ આવ્યા.', replacements: ['દાદા'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'દાદાશ્રીની ચોવીસેય કલાક ની સેવા _ મળી?' },
    options: 'કેમ,કોને,કોનું,કોનો',
    answers: { gu: 'કોને' },
    highlights: [
      {
        statement: 'દાદાશ્રીની ચોવીસેય કલાક ની સેવા _ મળી.',
        replacements: ['નીરુમાને']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'દાદાને _ પૂડા પસંદ હતા?' },
    options: 'કેવા,કેવું,કેવી,કેવો',
    answers: { gu: 'કેવા' },
    highlights: [
      { statement: 'દાદાને _ પસંદ હતા.', replacements: ['ઠંડા પૂડા'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાનું લક્ષ્ય _ હતું?' },
    options: 'કોણ,કયાં,કેવી,શું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમાનું લક્ષ્ય _ હતું.',
        replacements: ['જગત કલ્યાણ, મોક્ષ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'ખાવાની બાબતમાં દાદા _ આજ્ઞા માં રહેતા?' },
    options: 'કોને,કોની,કેમ,કોણ',
    answers: { gu: 'કોની' },
    highlights: [
      {
        statement: 'ખાવાની બાબતમાં દાદા _ આજ્ઞા માં રહેતા.',
        replacements: ['નીરુમાની']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'દાદાશ્રીએ _ પ્રોમિસ આપી હતી?' },
    options: 'કોને,કોની,કેમ,કોણ',
    answers: { gu: 'કોને' },
    highlights: [
      {
        statement: 'દાદાશ્રીએ _ પ્રોમિસ આપી હતી.',
        replacements: ['હીરાબા અને નીરુમાને']
      }
    ]
  },
  {
    level: '0',
    question: { gu: '_ મહિના પછી નીરુમાનું બધું ચોખ્ખું થઈ જશે?' },
    options: 'જેટલા,કેટલા,કેટલું,કેટલે',
    answers: { gu: 'કેટલા' },
    highlights: [
      {
        statement: '_ નીરુમાનું બધું ચોખ્ખું થઈ જશે.',
        replacements: ['૬ મહિના પછી']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'જ્ઞાન પ્રાપ્તિ સમયે નીરુમાની _ ઉંમર હતી?' },
    options: 'શું,કોણ,કયાં,કેવું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'જ્ઞાન પ્રાપ્તિ સમયે નીરુમાની ઉંમર _ હતી.',
        replacements: ['૨૩ વર્ષ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાની પ્રિય વાનગી _ છે?' },
    options: 'કોઈ,કઈ,કોની,કેમ',
    answers: { gu: 'કઈ' },
    highlights: [
      {
        statement: 'નીરુમાની પ્રિય વાનગી _ છે.',
        replacements: ['પાપડી નો લોટ અને મઠિયાં']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાનું સાચું નામ _ છે?' },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમાનું સાચું નામ _ છે.',
        replacements: ['નિરંજના બાલુભાઈ અમીન']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'એમના મમ્મી એમને _ શીખવાનું કહેતા?' },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'એમના મમ્મી એમને _ શીખવાનું કહેતા.', replacements: ['રસોઈ'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ દાદા વિશે સૌપ્રથમ _ પાસેથી સાંભળ્યું?' },
    options: 'કેવા,કેવું,કોની,કેવો',
    answers: { gu: 'કોની' },
    highlights: [
      {
        statement: 'નીરુમાએ દાદા વિશે સૌપ્રથમ _ પાસેથી સાંભળ્યું.',
        replacements: ['એમના મોટા ભાઈ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમા દાદાશ્રી માટે _ ભોજન બનાવતા?' },
    options: 'કેવા,કેવું,કોના,કેવો',
    answers: { gu: 'કેવું' },
    highlights: [
      {
        statement: 'નીરુમા દાદાશ્રી માટે _ ભોજન બનાવતા.',
        replacements: ['એમને માફક આવે એવા']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાના _ અવતાર થવાના છે?' },
    options: 'કેટલા,કેટલી,કેટલું,કેટલે',
    answers: { gu: 'કેટલા' },
    highlights: [
      { statement: 'નીરુમાનો _ થવાનો છે.', replacements: ['એક અવતાર'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'દાદાને _ માટે રાગ છે?' },
    options: 'કેવા,કેવું,કોના,કેવો',
    answers: { gu: 'કોના' },
    highlights: [
      { statement: 'દાદાને _ માટે રાગ છે.', replacements: ['નીરુમા'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ _ જીત્યા છે?' },
    options: 'કોણ,કયાં,કેવું,શું,કોને',
    answers: { gu: 'કોને' },
    highlights: [
      {
        statement: 'નીરુમાએ _ જીત્યા છે.',
        replacements: ['ક્રોધ-માન-માયા-લોભ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાનો અંતિમ સંદેશો _ છે?' },
    options: 'કેવા,કેવું,કોના,ક્યાં,શું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: "નીરુમાનો અંતિમ સંદેશો '_' છે.",
        replacements: ['પ્રેમથી રહેજો, પ્રોમિસ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાની યાદશક્તિ _ હતી?' },
    options: 'કેવો,કેવા,કેવે,કેવી',
    answers: { gu: 'કેવી' },
    highlights: [
      { statement: 'નીરુમાની યાદશક્તિ _ હતી.', replacements: ['જોરદાર'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમા આપ્તપુત્રી બહેનો માટે _ લાવ્યા?' },
    options: 'શા,શું,ક્યાં,કેમ',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમા આપ્તપુત્રી બહેનો માટે _ લાવ્યા.',
        replacements: ['એમની પસંદ ના જેકેટ']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાની માતાનું નામ _ છે?' },
    options: 'શું,કોણ,કયાં,કેવું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમાની માતાનું નામ _ છે.',
        replacements: ['વિજયાબેન અમીન']
      }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાને _ ભાઈઓ હતા?' },
    options: 'કેવા,શું,કેટલી,ક્યારે,કેટલા',
    answers: { gu: 'કેટલા' },
    highlights: [{ statement: 'નીરુમાને _ ભાઈઓ હતા.', replacements: ['પાંચ'] }]
  },
  {
    level: '0',
    question: { gu: '_ વર્ષે નીરુમા MBBS થયા?' },
    options: 'ક્યારે,કેવી રીતે,કયા,કેટલું,કેટલી',
    answers: { gu: 'કયા' },
    highlights: [
      { statement: '_ નીરુમા MBBS થયા.', replacements: ['૧૯૬૮ માં'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ _ મળવાનો નિશ્ચય કર્યો?' },
    options: 'કેમ,કોને,શા માટે,કેવી રીતે,શું',
    answers: { gu: 'કોને' },
    highlights: [
      { statement: 'નીરુમાએ _ મળવાનો નિશ્ચય કર્યો.', replacements: ['દાદાને'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'દાદાને _ ફ્રેકચર થયેલું?' },
    options: 'કેવું,કેમ,શાનું,કેટલા,કઈ',
    answers: { gu: 'શાનું' },
    highlights: [
      { statement: 'દાદાને _ ફ્રેકચર થયેલુ.', replacements: ['પગનું'] }
    ]
  },
  {
    level: '0',
    question: { gu: 'મહાત્માએ નીરુમાને _ જવાનું કહ્યું?' },
    options: 'શું,કેમ,કેટલા,ક્યાં,શા માટે',
    answers: { gu: 'ક્યાં' },
    highlights: [
      { statement: 'મહાત્માએ નીરુમાને _ જવાનું કહ્યું.', replacements: ['ઘરે'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'દાદાએ _ એમની સેવાને લાયક ગણ્યા?' },
    options: 'ક્યારે,કોને,કોણ,કેમ,કેટલા',
    answers: { gu: 'કોને' },
    highlights: [
      {
        statement: 'દાદાએ _ એમની સેવાને લાયક ગણ્યા.',
        replacements: ['નીરુમાને']
      }
    ]
  },
  {
    level: '1',
    question: { gu: '_ સુધી આપણે જોડે ને જોડે જ છીએ?' },
    options: 'કોણ,ક્યારે,ક્યાં,કેમ,કેવી રીતે',
    answers: { gu: 'ક્યાં' },
    highlights: [
      {
        statement: '_ સુધી આપણે જોડે ને જોડે જ છીએ.',
        replacements: ['મોક્ષે જતા']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'હજુ _ અવતાર થવાના છે આપણા?' },
    options: 'કેમ,કેટલા,ક્યારે,કોણે,કોણ',
    answers: { gu: 'કેટલા' },
    highlights: [
      { statement: 'હજુ _ અવતાર થવાના છે આપણો.', replacements: ['એક'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'દાદાએ નીરુમાની _ સુધીની જવાબદારી લીધી?' },
    options: 'કોણ,કેમ,શું,ક્યાં,ક્યારે',
    answers: { gu: 'ક્યાં' },
    highlights: [
      {
        statement: 'દાદાએ નીરુમાની _ સુધીની જવાબદારી લીધી.',
        replacements: ['ઠેઠ']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાના પિતાજી બાલુભાઈનું અવસાન _ સાલ માં થયું?' },
    options: 'કેમ,કઈ,ક્યારે,કેનું,શું',
    answers: { gu: 'કઈ' },
    highlights: [
      {
        statement: 'નીરુમાના પિતાજી બાલુભાઈનું અવસાન _ થયું.',
        replacements: ['૧૯૬૮માં']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'દાદાએ આવું કહ્યું એ નીરુમાને _ લાગ્યું?' },
    options: 'ક્યારે,કેવું,કેમ,કેવો,શા માટે',
    answers: { gu: 'કેવું' },
    highlights: [
      {
        statement: 'દાદાએ આવું કહું એ નીરુમાને _ લાગ્યું.',
        replacements: ['મીઠું']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનો દેહવિલય _ થયો?' },
    options: 'ક્યારે,કેવું,કેમ,કેવો,શા માટે',
    answers: { gu: 'ક્યારે' },
    highlights: [
      {
        statement: 'નીરુમાનો દેહવિલય _ માં થયો.',
        replacements: ['૧૯મી માર્ચ, ૨૦૦૬']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાની જન્મતારીખ _ હતી?' },
    options: 'કેમ,કઈ,ક્યારે,કેનું,શું',
    answers: { gu: 'કઈ' },
    highlights: [
      {
        statement: 'નીરુમાની જન્મતારીખ _ હતી.',
        replacements: ['૨ ડિસેમ્બર, ૧૯૪૪']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનો જન્મ _ થયો હતો?' },
    options: 'કોણ,ક્યાં,કેવું,શું',
    answers: { gu: 'ક્યાં' },
    highlights: [
      {
        statement: 'નીરુમાનો જન્મ _ થયો હતો.',
        replacements: ['ઔરંગાબાદ મહારાષ્ટ્રમાં']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાની જ્ઞાન તારીખ _ હતી?' },
    options: 'કોઈ,કઈ,કોની,કેમ',
    answers: { gu: 'કઈ' },
    highlights: [
      {
        statement: 'નીરુમાની જ્ઞાન તારીખ _ હતી.',
        replacements: ['૮મી જુલાઇ, ૧૯૫૮']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમા દાદાની નિરંતર _ કરવા ઈચ્છતા હતા?' },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમા દાદાની નિરંતર _ કરવા ઈચ્છતા હતા.',
        replacements: ['સેવા']
      }
    ]
  },
  {
    level: '1',
    question: {
      gu: 'જ્યારે ટીવી પર સત્સંગ શરૂ થયા ત્યારે શરૂઆતમાં _ કરવું પડતું હતું?'
    },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement:
          'જ્યારે ટીવી પર સત્સંગ શરૂ થયા ત્યારે શરૂઆતમાં _ કરવું પડતું હતું.',
        replacements: ['સ્પેશિયલ રેકોર્ડિંગ']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનું મૂળ વતન _ છે?' },
    options: 'કોણ,ક્યાં,કેવું,શું,કયું',
    answers: { gu: 'કયું' },
    highlights: [
      { statement: 'નીરુમાનું મૂળ વતન _ છે .', replacements: ['વસો'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમા દાદાને જ્યાં પ્રથમ મળ્યા તે શહેરનું નામ _ છે?' },
    options: 'શા,શું,ક્યાં,કેમ',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમા દાદાને જ્યાં પ્રથમ મળ્યા તે શહેરનું નામ _ છે.',
        replacements: ['વડોદરા']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનું સમાધી સ્થળ _ છે?' },
    options: 'કોણ,ક્યાં,કેવું,શું',
    answers: { gu: 'ક્યાં' },
    highlights: [
      {
        statement: 'નીરુમાનું સમાધી સ્થળ _માં છે.',
        replacements: ['સીમંધર સીટી અડાલજ']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાની _ જ્ઞાન ની દશા હતી?' },
    options: 'કોણ,કયાં,કેવું,કેવી',
    answers: { gu: 'કેવી' },
    highlights: [
      {
        statement: 'નીરુમાની _ થાય એવી જ્ઞાન ની દશા હતી.',
        replacements: ['આત્માનું સ્પષ્ટ વેદન']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનો _ બહુ ભારે હતો?' },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'નીરુમાનો _ બહુ ભારે હતો.', replacements: ['અહંકાર'] }
    ]
  },
  {
    level: '1',
    question: { gu: '_ પુસ્તકો વાંચી સંભળાવતા?' },
    options: 'કેવા,કેવું,શું,કેવી',
    answers: { gu: 'કેવા' },
    highlights: [
      { statement: '_ પુસ્તકો વાંચી સંભળાવતા.', replacements: ['ધર્મના'] }
    ]
  },
  {
    level: '1',
    question: { gu: '_ અજાયબ અને અદ્બુત છે?' },
    options: 'શા,શું,ક્યાં,કેમ',
    answers: { gu: 'શું' },
    highlights: [
      { statement: '_ અજાયબ અને અદ્બુત છે.', replacements: ['આ જ્ઞાન'] }
    ]
  },
  {
    level: '1',
    question: { gu: '_ સૂતા સૂતા દાદા બધું જોતા હતા?' },
    options: 'શા,શું,ક્યાં,કેમ',
    answers: { gu: 'ક્યાં' },
    highlights: [
      {
        statement: '_ સૂતા સૂતા દાદા બધું જોતા હતા.',
        replacements: ['પલંગમાં']
      }
    ]
  },
  {
    level: '1',
    question: { gu: 'દાદાની _ જબરજસ્ત હશે?' },
    options: 'શા,શું,ક્યાં,કેમ',
    answers: { gu: 'શું' },
    highlights: [{ statement: 'દાદાની _ જબરજસ્ત હશે.', replacements: ['કૃપા'] }]
  },
  {
    level: '1',
    question: { gu: 'દાદાશ્રી _ રહેતા હતા?' },
    options: 'કોણ,ક્યાં,કેવું,શું',
    answers: { gu: 'ક્યાં' },
    highlights: [
      { statement: 'દાદાશ્રી _ રહેતા હતા.', replacements: ['મામાની પોળમાં'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'નોન સ્ટોપ _ ચાલુ થઈ ગયો?' },
    options: 'કોણ,કયાં,કેવું,શું',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'નોન સ્ટોપ _ ચાલુ થઈ ગયો.', replacements: ['દાદાનો અવાજ'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'એનું _ બહુ ઉત્તમ છે?' },
    options: 'કોણ,કયાં,કેવું,શું,કેવી',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'એની _ બહુ ઉત્તમ છે.', replacements: ['શરણાગતિ'] }
    ]
  },
  {
    level: '1',
    question: { gu: 'એમણે _ કર્યું હોય તો તે પ્રમાણે ફેરફાર થઈ જાય?' },
    options: 'કોણ,કયાં,કેવું,શું,કેવી',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'એમણે _ કરી હોય તો તે પ્રમાણે ફેરફાર થઈ જાય.',
        replacements: ['ટકોર']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'એમના _ ચઢાવશે ને તોયે કલ્યાણ થશે?' },
    options: 'કયાં,કેવું,શું,કેવી',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'એમના _ ચઢાવશે ને તોયે કલ્યાણ થશે.',
        replacements: ['પગ નીચેની ધૂળ']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'એમનો _ ઉપર બહુ પ્રેમ હતો?' },
    options: 'કોણ,કયાં,કેવું,શું,શાના',
    answers: { gu: 'શાના' },
    highlights: [
      { statement: 'એમનો _ ઉપર બહુ પ્રેમ હતો.', replacements: ['જ્ઞાન'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'દાદાશ્રીએ પૂજ્ય નીરુમાને _ થવાનું કહ્યું?' },
    options: 'શું,કોણ,કયાં,કેવું,શા',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'દાદાશ્રીએ પૂજ્ય નીરુમાને _ થવાનું કહ્યુ.',
        replacements: ['આખા જગતના મધર']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'એમના રહેઠાણનું નામ _ ધરાવ્યું?' },
    options: 'શું,કોણ,કયાં,કેવું',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'એમના રહેઠાણનું નામ _ ધરાવ્યું.',
        replacements: ['વાત્સલ્ય']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'પૂજ્ય નીરુમા ખરેખર _ સમાન હતા?' },
    options: 'કોના,કોણ,કયાં,કેવું,કેવા',
    answers: { gu: 'કોના' },
    highlights: [
      {
        statement: 'પૂજ્ય નીરુમા ખરેખર _ સમાન હતા.',
        replacements: ['એક અજોડ વાત્સલ્યમૂર્તિ']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'પેલી બહેને નીરુમાને _ દેખાડ્યું?' },
    options: 'શું,કોણ,કયાં,કેવું',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'પેલી બહેને નીરુમાને _ દેખાડ્યું.', replacements: ['લીસ્ટ'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'બધાના _ વધી ગયા?' },
    options: 'શું,કોણ,કયાં,કેવું',
    answers: { gu: 'શું' },
    highlights: [{ statement: 'બધાના _ વધી ગયા.', replacements: ['ધબકારા'] }]
  },
  {
    level: '2',
    question: { gu: 'બધા _ થઈ ગયા?' },
    options: 'શું,કોણ,કયાં,કેવું ,શા',
    answers: { gu: 'શું' },
    highlights: [{ statement: 'બધા _ થઈ ગયા.', replacements: ['હળવા ફૂલ'] }]
  },
  {
    level: '2',
    question: { gu: 'નીરુમા દાદા પાછળ _ લઈને આવશે?' },
    options: 'કેવા,કેવું,કોના,ક્યાં,કોને',
    answers: { gu: 'કોને' },
    highlights: [
      { statement: 'નીરુમા દાદા પાછળ _ લઈને આવશે.', replacements: ['બધાને'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'ટીવી પર સત્સંગ સાંભળીને હજારો ઘરો _ થઈ ગયા?' },
    options: 'કેવા,કેવું,કોના,ક્યાં',
    answers: { gu: 'કેવા' },
    highlights: [
      {
        statement: 'ટીવી પર સત્સંગ સાંભળીને હજારો ઘરો _ થઈ ગયા.',
        replacements: ['સ્વર્ગ જેવા']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'એમણે _ કિંમત ક્યારેય મૂકી ન હતી?' },
    options: 'ક્યાં,ક્યાંની,કોણી,શાની',
    answers: { gu: 'શાની' },
    highlights: [
      {
        statement: 'એમણે _ કિંમત ક્યારેય મૂકી ન હતી.',
        replacements: ['વસ્તુની']
      }
    ]
  },
  {
    level: '2',
    question: { gu: '_ કિંમત મોટી ગણી હતી?' },
    options: 'કોની,કોનાં,કોણે,કોન',
    answers: { gu: 'કોની' },
    highlights: [
      { statement: '_ કિંમત મોટી ગણી હતી.', replacements: ['વ્યક્તિની'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'બધા દાદા નીરુમાના _ છે?' },
    options: 'ક્યાં,ક્યાંની,કોણી,શું',
    answers: { gu: 'શું' },
    highlights: [
      { statement: 'બધા દાદા નીરુમાના _ છે.', replacements: ['દિલના ટુકડા'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'દાદા રોજ _ વાગે જમીને નીકળતા?' },
    options: 'ક્યાં,ક્યાંની,કોણી,કોણે,કેટલા',
    answers: { gu: 'કેટલા' },
    highlights: [
      { statement: 'દાદા રોજ _ જમીને નીકળતા.', replacements: ['સાંજના ૬ વાગે'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'નીરુમા પૂડા ઉખેડવા જાય તો _ થઈ જાય?' },
    options: 'કોણ,કયાં,કેવું,શું,કેવી',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'નીરુમા પૂડા ઉખેડવા જાય તો _ થઈ જાય.',
        replacements: ['લોચો']
      }
    ]
  },
  {
    level: '2',
    question: { gu: '_ પૂડા સરસ ઉતર્યા?' },
    options: 'કયાં,કેવું,શું,કેવી ,કેટલા',
    answers: { gu: 'કેટલા' },
    highlights: [
      { statement: '_ પૂડો સરસ ઉતર્યો.', replacements: ['છેલ્લો એક'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'એમની મમ્મી એમને _ કરવાનું કહેતા?' },
    options: 'કોણ,કયાં,કેવું,શું,કેવી',
    answers: { gu: 'શું' },
    highlights: [
      {
        statement: 'એમની મમ્મી એમને _ કરવાનું કહેતા.',
        replacements: ['ભગવાનની ભક્તિ']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'આ જ્ઞાન જીવનના દરેક પ્રસંગોમાં _ રાખે છે?' },
    options: 'કેવા,કેવું,કોના,ક્યાં,કોણે',
    answers: { gu: 'કેવા' },
    highlights: [
      {
        statement: 'આ જ્ઞાન જીવનના દરેક પ્રસંગોમાં _ રાખે છે.',
        replacements: ['જીવનમુક્ત દશામાં']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'નીરુમાની સેવામાં _ હતા?' },
    options: 'કોન,કેવા,કેવો,કોણ',
    answers: { gu: 'કોણ' },
    highlights: [
      {
        statement: 'નીરુમાની સેવામાં _ હતી.',
        replacements: ['ઘણી બ્રહ્મચારી બહેનો']
      }
    ]
  },
  {
    level: '2',
    question: { gu: 'નીરુમા _ વર્ષ દાદાની સેવા માં રહ્યા?' },
    options: 'કેટલા,કેટલી,કેટલે,કેટલો',
    answers: { gu: 'કેટલા' },
    highlights: [
      { statement: 'નીરુમા _ દાદાની સેવામાં રહ્યા.', replacements: ['૨૦ વર્ષ'] }
    ]
  },
  {
    level: '2',
    question: { gu: '_ દુઃખ ના થવું જોઈએ?' },
    options: 'કોણ,કોન,કોને,ક્યાં',
    answers: { gu: 'કોને' },
    highlights: [
      { statement: '_ દુઃખ ના થવું જોઈએ.', replacements: ['વ્યક્તિને'] }
    ]
  },
  {
    level: '2',
    question: { gu: '_ જેવો તમારો પ્રેમ છે?' },
    options: 'કોના,કોણે,કોન,ક્યાં',
    answers: { gu: 'કોના' },
    highlights: [
      { statement: '_ જેવો તમારો પ્રેમ છે.', replacements: ['ઝવેરબા'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'હવેથી _ પાસે માંગશો?' },
    options: 'કોઈ,કઈ,કોના,કોન',
    answers: { gu: 'કોના' },
    highlights: [{ statement: 'હવેથી _ પાસે માંગશો.', replacements: ['મા'] }]
  },
  {
    level: '2',
    question: { gu: 'બધા ભાઈઓ સવારે _ ગયા?' },
    options: 'કયું,ક્યાં,શું,કઈ',
    answers: { gu: 'ક્યાં' },
    highlights: [
      { statement: 'બધા ભાઈઓ સવારે _ ગયા.', replacements: ['વાત્સલ્યમાં'] }
    ]
  },
  {
    level: '2',
    question: { gu: 'નીરુમાની જ્ઞાતિ _ છે?' },
    options: 'કેટલે,ક્યારે,કેમ,કઈ',
    answers: { gu: 'કઈ' },
    highlights: [
      { statement: 'નીરુમાની જ્ઞાતિ _ છે.', replacements: ['ચરોતર પટેલ'] }
    ]
  }
]
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false,
  true,
  {
    0: 'Set 1',
    1: 'Set 2',
    2: 'Set 3'
  }
)

export default pageDataNew
