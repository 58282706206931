const jointLetters =
[
  {
    letters: 'ર્,ક',
    answer: 'ર્ક'
  },
  {
    letters: 'ત્,ર ',
    answer: 'ત્ર'
  },
  {
    letters: 'અ,ઓ,મ',
    answer: 'ૐ'
  },
  {
    letters: 'ર્,ઉ',
    answer: 'રુ '
  },
  {
    letters: 'ર્,ઊ',
    answer: 'રૂ'
  },
  {
    letters: 'ક્,ઋ',
    answer: 'કૃ'
  },
  {
    letters: 'પ્,ઋ',
    answer: 'પૃ'
  },
  {
    letters: 'સ્,ઋ',
    answer: 'સૃ'
  },
  {
    letters: 'ત્,ઋ',
    answer: 'તૃ'
  },
  {
    letters: 'હ્,ઋ',
    answer: 'હૃ'
  },
  {
    letters: 'દ્,ઋ',
    answer: 'દૃ'
  },
  {
    letters: 'મ્,ર',
    answer: 'મ્ર'
  },
  {
    letters: 'ભ્,ર',
    answer: 'ભ્ર'
  },
  {
    letters: 'પ્,ર',
    answer: 'પ્ર'
  },
  {
    letters: 'ક્,ર',
    answer: 'ક્ર'
  },
  {
    letters: 'ટ્,ર',
    answer: 'ટ્ર'
  },
  {
    letters: 'ર્,ક',
    answer: 'ર્ક'
  },
  {
    letters: 'ર્,મ',
    answer: 'ર્મ'
  },
  {
    letters: 'ત્,ય',
    answer: 'ત્ય'
  },
  {
    letters: 'સ્,વ',
    answer: 'સ્વ'
  },
  {
    letters: 'સ્,ત્,ર',
    answer: 'સ્ત્ર'
  },
  {
    letters: 'ત્,સ્,ય',
    answer: 'ત્સ્ય'
  },
  {
    letters: 'લ્,લ',
    answer: 'લ્લ'
  },
  {
    letters: 'ન્,ન',
    answer: 'ન્ન'
  },
  {
    letters: 'ત્,ત',
    answer: 'ત્ત'
  },
  {
    letters: 'દ્,ધ',
    answer: 'દ્ધ'
  },
  {
    letters: 'ધ્,ધ',
    answer: 'ધ્ધ'
  },
  {
    letters: 'દ્,મ',
    answer: 'દ્મ'
  },
  {
    letters: 'દ્,વ',
    answer: 'દ્વ'
  },
  {
    letters: 'દ્,ર',
    answer: 'દ્ર'
  },
  {
    letters: 'દ્,ય',
    answer: 'દ્ય'
  },
  {
    letters: 'હ્,ય',
    answer: 'હ્ય'
  },
  {
    letters: 'હ્,મ',
    answer: 'હ્મ'
  },
  {
    letters: 'શ્,ર',
    answer: 'શ્ર'
  },
  {
    letters: 'શ્,વ',
    answer: 'શ્વ'
  },
  {
    letters: 'શ્,ચ',
    answer: 'શ્ચ'
  }
]
export default jointLetters
