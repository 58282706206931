import localeMessages from '../i18n'

const pageData = [
  { level: 'Kakko', text: 'ક', answers: ['કમળ', 'કલમ'] },
  { level: 'Kakko', text: 'દ', answers: ['દસ', 'દડો'] },
  { level: 'Kakko', text: 'પ', answers: ['પતંગ', 'પવન'] },
  { level: 'Kakko', text: 'મ', answers: ['મરચું', 'મન'] },
  { level: 'Kakko', text: 'લ', answers: ['લખોટી', 'લગન'] },
  { level: 'Kakko', text: 'શ', answers: ['શરબત', 'શરમ'] },
  { level: 'Kakko', text: 'બ', answers: ['બકરી', 'બળ'] },
  { level: 'Kakko', text: 'વ', answers: ['વહાણ', 'વન'] },
  { level: 'Kakko', text: 'હ', answers: ['હસ', 'હરણ'] },
  { level: 'Kakko', text: 'ર', answers: ['રમત', 'રજા'] },
  { level: 'Kakko', text: 'ટ', answers: ['ટપક', 'ટપાલી'] },
  { level: 'Kakko', text: 'ફ', answers: ['ફટાકડા', 'ફરજ'] },
  { level: 'Kakko', text: 'ખ', answers: ['ખમણ', 'ખબર'] },
  { level: 'Kakko', text: 'ચ', answers: ['ચમેલી', 'ચમત્કાર'] },
  { level: 'Kakko', text: 'સ', answers: ['સસલું', 'સરસ'] },
  { level: 'Barakhadi', text: 'કા', answers: ['કાગળ', 'કાતર'] },
  { level: 'Barakhadi', text: 'વિ', answers: ['વિમાન', 'વિશાળ'] },
  { level: 'Barakhadi', text: 'મા', answers: ['માન', 'માણસ'] },
  { level: 'Barakhadi', text: 'જી', answers: ['જીવન', 'જીભ'] },
  { level: 'Barakhadi', text: 'દા', answers: ['દાદા', 'દાસ'] },
  { level: 'Barakhadi', text: 'પો', answers: ['પોલ', 'પોળ'] },
  { level: 'Barakhadi', text: 'ગુ', answers: ['ગુણ', 'ગુજરાત'] },
  { level: 'Barakhadi', text: 'ફે', answers: ['ફેણ', 'ફેરફાર'] },
  { level: 'Barakhadi', text: 'અં', answers: ['અંજીર', 'અંકુર'] },
  { level: 'Barakhadi', text: 'રૂ', answers: ['રૂમાલ', 'રૂપ'] },
  { level: 'Color', text: 'લીલો', answers: ['પોપટ', 'પાન'] },
  { level: 'Color', text: 'લાલ', answers: ['ટામેટું', 'સફરજન'] },
  { level: 'Color', text: 'પીળો', answers: ['સૂર્ય', 'ગલગોટો'] },
  { level: 'Color', text: 'ગુલાબી', answers: ['કમળ', 'ગુલાબ'] },
  { level: 'Color', text: 'વાદળી', answers: ['વાદળ', 'આકાશ'] },
  { level: 'Color', text: 'જાંબલી', answers: ['રીંગણ', 'જાંબુ'] },
  { level: 'Color', text: 'કાળો', answers: ['વાળ', 'કાગડો'] },
  { level: 'Color', text: 'સફેદ', answers: ['મોગરો', 'સસલું'] },
  { level: 'Color', text: 'કેસરી', answers: ['નારંગી', 'ધજા'] },
  { level: 'Color', text: 'કથ્થઈ', answers: ['માટી', 'પહાડ'] },
  { level: 'Numbers', text: '૧૭', answers: ['સત્તર'] },
  { level: 'Numbers', text: '૨૮', answers: ['અઠ્યાવીસ'] },
  { level: 'Numbers', text: '૩૯', answers: ['ઓગણચાલીસ'] },
  { level: 'Numbers', text: '૪૫', answers: ['પિસ્તાલીસ'] },
  { level: 'Numbers', text: '૬૦', answers: ['સાંઠ'] },
  { level: 'Numbers', text: '૭૬', answers: ['છોત્તેર'] },
  { level: 'Numbers', text: '૮૯', answers: ['નેવ્યાસી'] },
  { level: 'Numbers', text: '૯૬', answers: ['છન્નું'] },
  { level: 'Numbers', text: '૫૩', answers: ['ત્રેપન'] },
  { level: 'Numbers', text: '૭૦', answers: ['સિત્તેર'] },
  { level: 'Number Names', text: 'સાડત્રીસ', answers: ['૩૭'] },
  { level: 'Number Names', text: 'પંદર', answers: ['૧૫'] },
  { level: 'Number Names', text: 'ચાલીસ', answers: ['૪૦'] },
  { level: 'Number Names', text: 'ત્રેપન', answers: ['૫૩'] },
  { level: 'Number Names', text: 'છ્યાસી', answers: ['૮૬'] },
  { level: 'Number Names', text: 'એંસી', answers: ['૮૦'] },
  { level: 'Number Names', text: 'પંચોતેર', answers: ['૭૫'] },
  { level: 'Number Names', text: 'નવ્વાણું', answers: ['૯૯'] },
  { level: 'Number Names', text: 'ચોવીસ', answers: ['૨૪'] },
  { level: 'Number Names', text: 'ઓગણસિત્તેર', answers: ['૬૯'] },
  {
    level: 'Words',
    text: 'અક્રમ જ્ઞાન',
    answers: [
      'દાદાશ્રી ને ૧૯૫૮ માં અક્રમ જ્ઞાન થયું.',
      'અક્રમ જ્ઞાન આખા જગત નું કલ્યાણ કરશે.',
      'કળિયુગ ના જીવો માટે અક્રમ જ્ઞાન તારણહાર છે.'
    ]
  },
  {
    level: 'Words',
    text: 'દાદા',
    answers: [
      'આપણા દાદા મહાન છે.',
      'દાદાના કૃપાપાત્ર બનવું.',
      'આજ્ઞા માં રેહવાથી દાદાનો રાજીપો રહે.'
    ]
  },
  {
    level: 'Words',
    text: 'નમસ્કાર',
    answers: [
      'શ્રી સીમંધર સ્વામી ને નમસ્કાર કરું છું.',
      'દાદા ભગવાન ને નમસ્કાર કરું છું.',
      'નીરુમા તથા પૂજ્ય શ્રી ને નમસ્કાર કરું છું.'
    ]
  },
  {
    level: 'Words',
    text: 'નીરુમા',
    answers: [
      'નીરુમા વાત્સલ્યમૂર્તિ હતાં.',
      'નીરુમા સદા દાદાની સેવામાં રહેતાં.',
      'નીરૂમાએ દાદાની પ્રત્યક્ષ વાણી ઝીલી હતી.'
    ]
  },
  {
    level: 'Words',
    text: 'પૂજ્યશ્રી',
    answers: [
      'પૂજ્યશ્રી અમારા માટે જીવતા તીર્થંકર છે.',
      'પૂજ્યશ્રી દીર્ઘાયુ રહો.',
      'સર્વસ્વ અમારું પૂજ્ય શ્ર્રી ને અર્પણ હો.'
    ]
  },
  {
    level: 'Words',
    text: 'ફાઈલો',
    answers: [
      'ફાઈલોનો સમભાવે નિકાલ કરવો.',
      'બધી ફાઈલો થી છૂટવું જ છે.',
      'આ ફાઈલો જ મોક્ષે જતાં અટકાવે છે.'
    ]
  },
  {
    level: 'Words',
    text: 'વીતરાગ',
    answers: [
      'અક્રમ જ્ઞાન વીતરાગ વિજ્ઞાન છે.',
      'કોઈ પણ પરિસ્થિતિ માં વીતરાગ રહેવું.',
      'તીર્થંકરો સંપૂર્ણ વીતરાગ હોય.'
    ]
  },
  {
    level: 'Words',
    text: 'વ્યવસ્થિત',
    answers: [
      'આ જગત ને વ્યવસ્થિત શક્તિ જ ચલાવે છે.',
      'વ્યવ્સ્થીતની આજ્ઞા અકર્તા પદ માં મૂકનારી છે.',
      'વ્યવસ્થિત ની બહાર કઈ જ થવાનું નથી.'
    ]
  },
  {
    level: 'Words',
    text: 'શુદ્ધાત્મા',
    answers: [
      'હું શુદ્ધાત્મા છું.',
      'મારે બધા માં શુદ્ધાત્માના દર્શન કરવા છે.',
      'હું કેવળ શુધ્ધાત્માનો અનુભવ જ ઝંખું છું.'
    ]
  },
  {
    level: 'Words',
    text: 'સીમંધર સ્વામી',
    answers: [
      'સીમંધર સ્વામી વર્તમાન તીર્થંકર છે.',
      'સર્વ જીવો ને સીમંધર સ્વામી નું શરણ મળે.',
      'મોક્ષ થતાં સુધી સીમંધર સ્વામી નું જ શરણું હોજો.'
    ]
  }
]
export default {
  levels: {
    eng: Array.from(new Set(pageData.map((v) => v.level))).map((v) => ({
      text: v,
      value: v
    })),
    hindi: Array.from(new Set(pageData.map((v) => v.level))).map((v) => ({
      text: localeMessages.messages['hi'][v],
      value: v
    })),
    guj: Array.from(new Set(pageData.map((v) => v.level))).map((v) => ({
      text: localeMessages.messages['gu'][v],
      value: v
    }))
  },
  pageData: pageData,
  defaultLevel: pageData[0].level
}
