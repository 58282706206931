export default {
  gujaratiWordSeperator(chars) {
    const punctuations = [
      2689, 2690, 2691, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2756,
      2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764
    ]
    if (chars.length > 0) {
      const updatedWord = []
      for (let i = 0; i < chars.length; i++) {
        if (
          !punctuations.includes(chars[i].charCodeAt(0)) &&
          i + 2 < chars.length &&
          punctuations.includes(chars[i + 1].charCodeAt(0)) &&
          punctuations.includes(chars[i + 2].charCodeAt(0))
        ) {
          updatedWord.push(chars[i] + chars[i + 1] + chars[i + 2])
          i = i + 2
        } else if (
          !punctuations.includes(chars[i].charCodeAt(0)) &&
          i + 1 < chars.length &&
          punctuations.includes(chars[i + 1].charCodeAt(0))
        ) {
          updatedWord.push(chars[i] + chars[i + 1])
          i++
        } else if (
          !punctuations.includes(chars[i].charCodeAt(0)) &&
          i + 1 < chars.length &&
          chars[i + 1].charCodeAt(0) === 2765
        ) {
          if (
            i + 3 < chars.length &&
            punctuations.includes(chars[i + 3].charCodeAt(0))
          ) {
            updatedWord.push(
              chars[i] + chars[i + 1] + chars[i + 2] + chars[i + 3]
            )
            i = i + 3
          } else if (i + 2 < chars.length) {
            updatedWord.push(chars[i] + chars[i + 1] + chars[i + 2])
            i = i + 2
          }
        } else if (
          !punctuations.includes(chars[i].charCodeAt(0)) &&
          i + 1 < chars.length &&
          chars[i + 1].charCodeAt(0) === 2765
        ) {
          updatedWord.push(chars[i] + chars[i + 1])
          i++
        } else {
          updatedWord.push(chars[i])
        }
      }

      if (
        punctuations.includes(updatedWord[updatedWord.length - 1].charCodeAt(0))
      ) {
        updatedWord[updatedWord.length - 2] =
          updatedWord[updatedWord.length - 2] +
          updatedWord[updatedWord.length - 1]
        updatedWord.pop()
      }
      return updatedWord
    }
    return chars
  },

  dropConfetti(confetti) {
    confetti.start({
      particlesPerFrame: 1,
      windSpeedMax: 0,
      dropRate: 1,
      size: 200,
      particles: [
        {
          type: 'image',
          url: 'https://www.trimandir.org/images/menu/menu-dada.jpg'
        },
        {
          type: 'image',
          url: 'https://www.trimandir.org/images/menu/menu-NM.jpg'
        },
        {
          type: 'image',
          url: 'https://www.trimandir.org/images/menu/menu-pujyashree.jpg'
        }
      ]
    })
    setTimeout(function () {
      confetti.stop()
    }, 3000)
  },

  shuffle(array) {
    const tempArray = [...array]
    let currentIndex = array.length
    let randomIndex

    if (array.length === 2) {
      this.swap(array, 0, 1)
    } else {
      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        this.swap(array, currentIndex, randomIndex)
      }
    }
    if (tempArray.join('') === array.join('')) {
      this.swap(array, 0, 1)
    }
    if (tempArray.join('') === array.join('')) {
      this.swap(array, 1, 2)
    }
    return array
  },
  swap(array, from, to) {
    const temporaryValue = array[from]
    array[from] = array[to]
    array[to] = temporaryValue
  },
  /**
   * @description
   * Takes an Array<V>, and a grouping function,
   * and returns a Map of the array grouped by the grouping function.
   *
   * @param list An array of type V.
   * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
   *                  K is generally intended to be a property key of V.
   *
   * @returns Map of the array grouped by the grouping function.
   */
  // export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  //    const map = new Map<K, Array<V>>();
  groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  },

  getDefaultLevels(data) {
    const defaultLevels = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Advanced'
    }
    const levels = Array.from(new Set(data.map((v) => v.level))).map((v) => ({
      text: defaultLevels[v],
      value: v
    }))
    return levels
  },

  fillInBlanksDataMassager(
    data,
    showQuestionHeader,
    showQuestionWords,
    hasOnlyCorrectAnswer,
    hideLevelOption,
    displayHint,
    enableAnswersLocale,
    forceShowPossibleAnswers,
    customLevels
  ) {
    data
      .map((r) => {
        r.gu = [r.question.gu]
        r.options = r.options.trim().replaceAll(', ', ',').split(',')
        Object.keys(r.answers).forEach((k) => {
          r.answers[k] = r.answers[k].replaceAll(', ', ',').split(',')
        })

        r.highlightStatement = r.question.gu
          .replace(/\([^\)]+\)/g, '')
          .replace('  ', ' ')
        return r
      })
      .sort((a, b) => a.level - b.level)
    data.forEach((v) => {
      v.punctuation = ''
      var replaced = v.gu[0]
      for (var i = 0; i < v.answers.gu.length; i++) {
        replaced = replaced.replace('_', v.answers.gu[i])
      }
      v.gu[0] = replaced
      v.optionsState = {
        data: v.options.map((option) => {
          return {
            text: option,
            enabled: true,
            wordStateIndex: null,
            onOptionDrag: function (wordState) {
              if (wordState.actual != '') {
                v.optionsState.onWordClear(wordState.actual)
              }
              this.enabled = false
              v.wordsState.onOptionDrag(this.text, wordState)
            },
            onOptionSelection: function () {
              var unanswered = v.wordsState.onOptionSelection(this.text)
              if (unanswered) {
                this.enabled = false
              }
            }
          }
        }),
        onWordClear: function (wordText) {
          v.optionsState.data.find(
            (o) => !o.enabled && o.text == wordText
          ).enabled = true
        },
        reset: function () {
          v.optionsState.data.forEach((e) => (e.enabled = true))
        }
      }
      var wordsCounter = -1
      var question = v.question.gu
      var hasPlaceholder = function (w) {
        return w.indexOf('_') >= 0
      }
      v.wordsState = {
        question: question,
        words: question.split(' ').map((word) => {
          if (hasPlaceholder(word)) {
            wordsCounter++
          }
          return {
            beforeText: hasPlaceholder(word) ? word.split('_')[0] : '',
            afterText: hasPlaceholder(word) ? word.split('_')[1] : word,
            hasFocus: hasPlaceholder(word) && wordsCounter == 0,
            query: hasPlaceholder(word),
            expected: hasPlaceholder(word) ? v.answers.gu[wordsCounter] : word,
            answerHint: {
              gu: 'Hint',
              en:
                hasPlaceholder(word) && v.answers.en
                  ? v.answers.en[wordsCounter]
                  : word,
              hi:
                hasPlaceholder(word) && v.answers.hi
                  ? v.answers.hi[wordsCounter]
                  : word
            },
            actual: hasPlaceholder(word) ? '' : word,
            reset: function () {
              if (this.query) this.actual = ''
            },
            wordLength: function () {
              return this.expected.length
            },
            onWordClear: function () {
              v.optionsState.onWordClear(this.actual)
              this.actual = ''
              v.wordsState.updateHasFocus()
            },
            isAnsweredCorrectly: function () {
              return this.expected == this.actual
            }
          }
        }),
        onOptionDrag: function (text, wordState) {
          wordState.actual = text
          this.updateHasFocus()
        },
        onOptionSelection: function (text) {
          var unanswered = v.wordsState.words.find((w) => w.hasFocus)

          if (unanswered) {
            unanswered.actual = text
            this.updateHasFocus()
          }
          return unanswered
        },
        updateHasFocus: function () {
          v.wordsState.words.forEach((w) => (w.hasFocus = false))
          var unanswered = v.wordsState.words.find(
            (w) => w.query && w.actual == ''
          )
          if (unanswered) unanswered.hasFocus = true
        },
        isAnsweredCorrectly: function () {
          return v.wordsState.words.every((v) => v.isAnsweredCorrectly())
        },
        reset: function () {
          this.words.forEach((v) => v.reset())
          this.words.find((v) => v.query).hasFocus = true
        }
      }
    })

    const defaultLevels = customLevels || {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Advanced'
    }
    const levels = Array.from(new Set(data.map((v) => v.level))).map((v) => ({
      text: defaultLevels[v],
      value: v
    }))

    return {
      levels: levels,
      showQuestionHeader: showQuestionHeader,
      showQuestionWords: showQuestionWords,
      hasOnlyCorrectAnswer: hasOnlyCorrectAnswer,
      hideLevelOption: hideLevelOption,
      displayHint: displayHint,
      enableAnswersLocale: enableAnswersLocale || false,
      forceShowPossibleAnswers: forceShowPossibleAnswers,
      sentences: data,
      reset: function () {
        data.forEach((v) => {
          v.wordsState.reset()
          v.optionsState.reset()
        })
      }
    }
  },

  singleChoiceDataMassager(
    data,
    showQuestionHeader,
    showQuestionWords,
    hidePossibleAnswersPanel,
    hideLevelOption,
    displayHint,
    instructionsKey,
    customLevels,
    showTranslatedOptions
  ) {
    data.map((r) => {
      r.options = r.options
        ? r.options.trim().replaceAll(', ', ',').split(',')
        : []
      r.answers.gu = [r.answers.gu]
      return r
    })
    data.forEach((v) => {
      v.punctuation = v.question.gu.slice(-1)
      v.gu = [v.question.gu]
      v.optionsState = {
        data: v.options.map((option) => {
          return {
            text: option,
            enabled: true,

            onOptionDrag: function (wordState) {},
            onOptionSelection: function () {
              v.optionsState.data.forEach((e) => (e.enabled = true))
              this.enabled = false
            },
            isExpectedAnswer: function () {
              return this.text.toLowerCase() == v.answers.gu[0]
            },
            isAnsweredCorrectly: function () {
              var isAnswer = this.text.toLowerCase() == v.answers.gu[0]
              var isAnsweredCorrectly =
                (isAnswer && !this.enabled) || (!isAnswer && this.enabled)
              return isAnsweredCorrectly
            }
          }
        }),
        onWordClear: function (wordText) {},
        reset: function () {
          v.optionsState.data.forEach((e) => (e.enabled = true))
        }
      }
      var questionWords = v.question.gu[0].split(' ')
      v.wordsState = {
        question: v.question.gu,
        words: questionWords.map((word) => {
          return {
            query: true,
            expected: word,
            actual: word,
            hasFocus: false,
            selected: false,
            reset: function () {
              this.selected = false
            },
            wordLength: function () {
              return this.expected.length
            },
            onWordClear: function () {
              v.wordsState.words.forEach((w) => {
                w.hasFocus = false
                w.selected = false
              })
              this.hasFocus = true
              this.selected = true
            }
          }
        }),
        isAnsweredCorrectly: function (derivedAnswer) {
          if (derivedAnswer) {
            return derivedAnswer == v.answers.gu
          }
          var correctOptionSelected = v.optionsState.data.every((o) =>
            o.isAnsweredCorrectly()
          )
          return correctOptionSelected
        },
        onOptionDrag: function (text, wordState) {},
        reset: function () {
          this.words.forEach((v) => v.reset())
        }
      }
    })
    const defaultLevels = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Advanced'
    }
    const levels = Array.from(new Set(data.map((v) => v.level))).map((v) => ({
      text: defaultLevels[v],
      value: v
    }))
    return {
      singleChoiceDataMassager: true,
      instructionsKey: instructionsKey,
      levels: levels,
      showQuestionHeader: true,
      showQuestionWords: false,
      hidePossibleAnswersPanel: true,
      sentences: data,
      hideLevelOption: hideLevelOption,
      showTranslatedOptions: showTranslatedOptions,
      reset: function () {
        data.forEach((v) => {
          v.wordsState.reset()
          v.optionsState.reset()
        })
      }
    }
  },
  imgUrlByPath(assetPath) {
    const images = require.context('../assets/', true, /\.(svg|png|jpg)$/)
    return images('./' + assetPath)
  },
  imgUrl(folder, filename) {
    const images = require.context('../assets/', true, /\.(svg|png|jpg)$/)
    return images(`./${folder}/` + filename)
  },
  imgUrlOrUndefined(assetPath) {
    const images = require.context('../assets/', true, /\.(svg|png|jpg)$/)
    let url = undefined
    try {
      url = images('./' + assetPath)
    } catch (e) {
      //ignore
    }
    return url
  },
  audioByPath(assetPath) {
    const images = require.context('../assets/', true, /\.(mp3|wav)$/)
    return images('./' + assetPath)
  }
}
