<template>
  <v-menu
    open-on-click
    close-on-content-click
    offset-y
    open-delay="100"
    close-delay="100"
    transition="scale-transition"
    origin="center center"
    ref="parentMenuRef"
    width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        plain
        v-bind="attrs"
        v-on="on"
        class="menuBtn mr-5 text-h6 font-weight-regular"
      >
        {{ $t(menu.name) }}
        <v-icon color="primary">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list
      :style="{
        borderTop: '3px solid var(--v-primary-base)',
        paddingRight: '10px'
      }"
    >
      <v-list-item
        v-for="(parentMenu, index) in menu.items"
        :key="index"
        class="menuList"
        @click.prevent="gotoMenu(parentMenu)"
      >
        <v-list-item-title
          class="menuTitle"
          v-if="parentMenu.subMenu.length == 0"
        >
          {{ parentMenu.name[$i18n.locale] }}
        </v-list-item-title>
        <v-menu
          v-else
          open-on-hover
          close-on-content-click
          offset-x
          open-delay="100"
          close-delay="100"
          transition="scale-transition"
          origin="center center"
          nudge-right="10"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title class="menuTitle" v-on="on">
              {{ parentMenu.name[$i18n.locale] }}
              <v-icon color="primary" class="float-right mr-1"
                >mdi-chevron-right</v-icon
              >
            </v-list-item-title>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in parentMenu.subMenu"
              :key="index"
            >
              <v-list-item-title
                @click="gotoChildMenu(parentMenu, item)"
                class="menuList menuTitle"
              >
                {{ item.name[$i18n.locale] }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    gotoMenu(parentMenu) {
      if (parentMenu.link) {
        window.open(parentMenu.link, '_blank')
      } else {
        this.$router.push(`/${this.menu.name.toLowerCase()}/${parentMenu.id}`)
        this.$refs.parentMenuRef.isActive = false
      }
    },
    gotoChildMenu(parentMenu, item) {
      this.$router.push(
        `/${this.menu.name.toLowerCase()}/${parentMenu.id}/${item.id}`
      )
      this.$refs.parentMenuRef.isActive = false
    }
  }
}
</script>

<style scoped>
.menuBtn {
  height: 100px !important;

  text-transform: none;
  color: var(--v-gray-darken4);
}

.menuBtn:hover {
  color: var(--v-primary-base);
}
.subMenuBtn {
  padding: 0px !important;
  text-transform: none;
}
.menuList {
  padding-right: 0px;
  width: 100%;
  cursor: pointer;
}
.menuList:hover {
  color: var(--v-primary-base) !important;
}
.v-btn__content {
  opacity: 1 !important;
}
</style>
