const jointWords =
[
  { letter: 'દ્ધ', guj: 'શદ્ધુ ાત્મા', eng: 'Shudhdhatma', hindi: 'क' },
  { letter: 'દ્મ', guj: 'પદ્માવતી દેવી', eng: 'Padmavati Devi', hindi: 'क' },
  { letter: 'ધ્ધ', guj: 'સધ્ધર', eng: 'Sadhdhar', hindi: 'क' },
  { letter: 'દ્વ', guj: 'દ્વેષ', eng: 'Dvesh', hindi: 'क' },
  { letter: 'દ્ર', guj: 'દ્રવ્ય', eng: 'Drvya', hindi: 'क' },
  { letter: 'દ્ય', guj: 'વિદ્યા', eng: 'Vidya', hindi: 'क' },
  { letter: 'શ્ર', guj: 'શ્રેણી', eng: 'Shreni', hindi: 'क' },
  { letter: 'શ્વ', guj: 'ઈશ્વર', eng: 'Ishvar', hindi: 'क' },
  { letter: 'હ્ય', guj: 'બાહ્ય', eng: 'Bahya', hindi: 'क' },
  { letter: 'હ્મ', guj: 'બ્રહ્મ', eng: 'Brahm', hindi: 'क' },
  { letter: 'પ્ર', guj: 'પ્રોમીસ', eng: 'Promise', hindi: 'क' },
  { letter: 'ક્ર', guj: 'અક્રમ', eng: 'Akram', hindi: 'क' },
  { letter: 'ત્ર', guj: 'ત્રિમંત્ર', eng: 'Trimantra', hindi: 'क' },
  { letter: 'ર્ક', guj: 'અર્ક', eng: 'Ark', hindi: 'क' },
  { letter: 'સ્ત્ર', guj: 'સહસ્ત્ર', eng: 'Sahstra', hindi: 'क' },
  { letter: 'ત્ય', guj: 'સત્ય', eng: 'Satya', hindi: 'क' },
  { letter: 'ત્ત', guj: 'ચિત્ત', eng: 'Chitt', hindi: 'क' },
  { letter: 'સૃ', guj: 'સૃષ્ટિ', eng: 'Srushti', hindi: 'क' },
  { letter: 'તૃ', guj: 'તૃષા', eng: 'Trusha', hindi: 'क' },
  { letter: 'ત્સ્ય', guj: 'મત્સ્ય', eng: 'Mtsya', hindi: 'क' },
  { letter: 'હૃ', guj: 'હૃદય', eng: 'Hridya', hindi: 'क' },
  { letter: 'શ્ચ', guj: 'નિશ્ચય', eng: 'Nischay', hindi: 'क' }

]
export default jointWords
