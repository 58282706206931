const listenSpeak = [
  {
    level: '0',
    question: {
      gu: 'હું શુદ્ધાત્મા છું.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'અમે ફરવા ગયા હતાં.'
    },
    answers: ['અમે ફરવા ગયા હતા.']
  },
  {
    level: '0',
    question: {
      gu: 'હું દાદાનાં દર્શન કરીશ.'
    },
    answers: ['હું દાદાના દર્શન કરીશ.', 'હું દાદા ના દર્શન કરીશ.']
  },
  {
    level: '0',
    question: {
      gu: 'બાગમાં છોકરાં રમતા હતાં.'
    },
    answers: ['બાગમાં છોકરા રમતા હતા.']
  },
  {
    level: '0',
    question: {
      gu: 'તેઓને કારમાં જવું છે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'અમે સુખડી બનાવીશું.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'બધાએ જ્ઞાન લીધું.'
    },
    answers: ['બધા એ જ્ઞાન લીધું.']
  },
  {
    level: '0',
    question: {
      gu: 'અમે સ્કૂલમાં ભણીએ છીએ.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'તેઓ સ્વામીનાં દર્શન કરશે.'
    },
    answers: ['તેઓ સ્વામીના દર્શન કરશે.']
  },
  {
    level: '0',
    question: {
      gu: 'મને ફરવા જવું બહુ ગમે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'મને સાયકલ ચલાવતા આવડશે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'તમારા ફોનની ઘંટડી વાગે છે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'શું તમને વહેલા ઉઠવાનું ફાવશે?'
    },
    answers: ['શું તમને વહેલા ઉઠવાનું ફાવશે.']
  },
  {
    level: '0',
    question: {
      gu: 'આજે પૂજ્યશ્રીનો લાઇવ સત્સંગ છે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'મમ્મી-પપ્પાનો વિનય નહીં ચૂકું.'
    },
    answers: ['મમ્મી પપ્પા નો વિનય નહીં ચૂકું.']
  },
  {
    level: '0',
    question: {
      gu: 'મને નીરુમાને મળવું છે.'
    },
    answers: ['મને નીરુમા ને મળવું છે.']
  },
  {
    level: '0',
    question: {
      gu: 'હું નિયમિત કસરત કરું છું.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'તમને હમણાં જમવું છે?'
    },
    answers: ['તમને હમણાં જમવું છે.']
  },
  {
    level: '0',
    question: {
      gu: 'તે બાળક હજુ સૂતેલું છે.'
    },
    answers: ['તે બાળક હજુ સુતેલું છે.']
  },
  {
    level: '0',
    question: {
      gu: 'અમે અક્રમ વિજ્ઞાનને સમજીશું.'
    },
    answers: ['અમે અક્રમ વિજ્ઞાન ને સમજીશું.']
  },
  {
    level: '0',
    question: {
      gu: 'હું વીકલી સત્સંગમાં જાઉં છું.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'બાળકો સુંદર ચિત્રો દોરે છે.'
    },
    answers: []
  },
  {
    level: '0',
    question: {
      gu: 'અમે શિબિરમાં ધન્યતા અનુભવીશું.'
    },
    answers: ['અમે શિબિરમાં ધન્યતા અનુભવી શું.']
  },
  {
    level: '0',
    question: {
      gu: 'વસ્તુના ૧૦૨ રૂપિયા થયા.'
    },
    answers: ['વસ્તુના 102 રૂપિયા થયા.']
  },
  {
    level: '0',
    question: {
      gu: 'મને જમવામાં શીરો ભાવશે.'
    },
    answers: ['મને જમવામાં શીરો ભાવ શે.', 'મને જમવામાં શીરો ભાવ છે.']
  },
  {
    level: '1',
    question: {
      gu: 'મેં નીરુમા રચિત ઘણાં પદો સાંભળ્યા છે.'
    },
    answers: ['મેં નીરુમા રચિત ઘણા પદો સાંભળ્યા છે.']
  },
  {
    level: '1',
    question: {
      gu: 'મોક્ષ ખીચડી બનાવવા કરતાં પણ સહેલો છે.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'દાદાનો સત્સંગ અહંકારને ઓગાળશે.'
    },
    answers: [
      'દાદા નો સત્સંગ અહંકાર ને ઓગાળશે.',
      'દાદા નો સત્સંગ અહંકાર ની ઓગાળશે.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'વારસ અહો! દાદાના શૂરવીરતા રેલાવજો.'
    },
    answers: [
      'વારસ અહો દાદાના સુરવીર તાર લાવજો.',
      'વારસ અહો દાદા ના સુરવીર તાર લાવજો.',
      'વારસ અહો! દાદાના શુરવીરતા રેલાવજો.',
      'વારસ અહો! દાદાના શૂરવીરતા રેલાવજો.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'દાદાની જન્મજયંતી અમે ઘરે પણ ઉજવીએ છીએ.'
    },
    answers: ['દાદા ની જન્મ જયંતી અમે ઘરે પણ ઉજવીએ છીએ.']
  },
  {
    level: '1',
    question: {
      gu: 'આજે મહાત્માઓ આનંદનો અનુભવ કરશે.'
    },
    answers: ['આજે મહાત્માઓ આનંદ નો અનુભવ કરશે.']
  },
  {
    level: '1',
    question: {
      gu: 'પૂજ્યશ્રી નિરંતર આત્મામાંજ રહે છે.'
    },
    answers: [
      'પૂજ્યશ્રી નિરંતર આત્મા માં જ રહે છે.',
      'પૂજ્ય શ્રી નિરંતર આત્મા માં જ રહે છે.',
      'પૂજ્ય શ્રી નિરંતર આત્મામાં જ રહે છે.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'મારી બહેનપણી જોડે હું દાદા દરબારમાં ગઈ.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'દ્વેષથી આખો સંસાર ઊભો રહ્યો છે.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'આવતીકાલે પૂજ્યશ્રી બધા બાળકો માટે પરીક્ષાની વિધિ કરશે.'
    },
    answers: ['આવતીકાલે પૂજ્યશ્રી બધા બાળકો માટે પરીક્ષા ની વિધિ કરશે.', '', '']
  },
  {
    level: '1',
    question: {
      gu: 'એકજ નિશ્ચય કરો કે આપણે મોક્ષે જવું છે.'
    },
    answers: ['એક જ નિશ્ચય કરો કે આપણે મોક્ષે જવું છે.']
  },
  {
    level: '1',
    question: {
      gu: 'પૂજ્યશ્રી સાથે બધા મહાત્માઓ પણ હસતાં હતાં.'
    },
    answers: [
      'પૂજ્ય શ્રી સાથે બધા મહાત્માઓ પણ હસતા હતા.',
      'પૂજ્યશ્રી સાથે બધા મહાત્માઓ પણ હસતા હતા.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'તેજસ વકૃત્વ સ્પર્ધામાં ઇનામ જીત્યો.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'હું આપને હૃદયપૂર્વક પ્રાર્થના કરું છું.'
    },
    answers: ['હું આપને હૃદય પૂર્વક પ્રાર્થના કરું છું.']
  },
  {
    level: '1',
    question: {
      gu: 'એક્સિડન્ટ થયું ત્યારે વ્યવસ્થિતનું જ્ઞાન મને હાજર રહ્યું.'
    },
    answers: ['એક્સિડન્ટ થયું ત્યારે વ્યવસ્થિત નું જ્ઞાન મને હાજર રહ્યું.', '']
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમાએ દાદાની સાથે ફરી તેમની વાણી ટેપ કરી.'
    },
    answers: ['નીરુમા એ દાદાની સાથે ફરી તેમની વાણી ટેપ કરી.']
  },
  {
    level: '1',
    question: {
      gu: 'શુદ્ધાત્મામાં આવ્યા પછી વિજ્ઞાનઘન પદને જાણવું જોઈએ.'
    },
    answers: [
      'શુદ્ધાત્મા માં આવ્યા પછી વિજ્ઞાન ઘન પદને જાણવું જોઈએ.',
      'શુદ્ધાત્મામાં આવ્યા પછી વિજ્ઞાન ઘન પદને જાણવું જોઈએ.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'છેક મોક્ષે જતા સુધી જોડે જ રાખીશું.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'આજે લાખો લોકો દાદાના જ્ઞાનને પામી રહ્યા છે.'
    },
    answers: [
      'આજે લાખો લોકો દાદા ના જ્ઞાનને પામી રહ્યા છે.',
      'આજે લાખો લોકો દાદા ના જ્ઞાન ને પામી રહ્યા છે.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમા એમના મમ્મીને ધર્મના પુસ્તકો વાંચી સંભળાવતા.'
    },
    answers: []
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમા ખરેખર એક અજોડ વાત્સલ્યમૂર્તિ સમાન હતાં.'
    },
    answers: ['નીરુમા ખરેખર એક અજોડ વાત્સલ્ય મૂર્તિ સમાન હતા.']
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમા માત્ર બેસતા વર્ષના દિવસે કૃષ્ણ ભગવાનના દર્શન કરવા જતાં.'
    },
    answers: [
      'નીરુમા માત્ર બેસતા વર્ષના દિવસે કૃષ્ણ ભગવાનના દર્શન કરવા જતા.',
      'નીરુમા માત્ર બેસતા વર્ષ ના દિવસે કૃષ્ણ ભગવાનના દર્શન કરવા જતા.'
    ]
  },
  {
    level: '1',
    question: {
      gu: 'તેણે જલ્દીથી ખીચડી-શાક રાંધ્યા.'
    },
    answers: ['તેણે જલ્દીથી ખીચડી શાક રાંધ્યા.']
  },
  {
    level: '1',
    question: {
      gu: 'બજારમાંથી તું થોડા ગલકા લઈ આવીશ?'
    },
    answers: ['બજારમાંથી તું થોડા ગલકા લઈ આવીશ.']
  },
  {
    level: '1',
    question: {
      gu: 'તમે જ્ઞાની સંગે જાત્રા માણજો રે, મહાત્માઓ!'
    },
    answers: [
      'તમે જ્ઞાની સંગે જાત્રા માણજો રે મહાત્માઓ.',
      'તમે જ્ઞાની સંગે જાતરા માણજો રે મહાત્માઓ.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'જાત્રામાં ખૂબ સેવાર્થી હાજર હતા અને તેઓએ દિલથી સેવા કરી.'
    },
    answers: [
      'જાત્રામાં ખૂબ સેવાથી હાજર હતા અને તેઓએ દિલથી સેવા કરી.',
      'જાત્રામાં ખૂબ સેવા થી હાજર હતા અને તેઓએ દિલથી સેવા કરી.'
    ]
  },
  {
    level: '2',
    question: {
      gu: '૧૮ વર્ષની વયે દાદાશ્રીના લગ્ન હીરાબા સાથે થયા અને તેઓ ઘણું સરળ જીવન જીવતાં.'
    },
    answers: [
      '18 વર્ષની વયે દાદાશ્રી ના લગ્ન હીરાબા સાથે થયા અને તેઓ ઘણું સરળ જીવન જીવતા.',
      '18 વર્ષની વયે દાદાશ્રીના લગ્ન હીરાબા સાથે થયા અને તેઓ ઘણું સરળ જીવન જીવતા.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'કાલે ગુરુપૂર્ણિમાના દિવસે દાદા પૂર્ણ હાજર હશે અને સવારે શોભાયાત્રા નીકળશે.'
    },
    answers: [
      'કાલે ગુરુપૂર્ણિમા ના દિવસે દાદા પૂર્ણ હાજર હશે અને સવારે શોભાયાત્રા નીકળશે.',
      'કાલે ગુરુ પૂર્ણિમા ના દિવસે દાદા પૂર્ણ હાજર હશે અને સવારે શોભાયાત્રા નીકળશે.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'પૂજ્યશ્રીનો આજનો સત્સંગ માન ઉપર હતો જેમાં તેમણે સુંદર સૂચનો સૂચવ્યા.'
    },
    answers: [
      'પૂજ્યશ્રી નો આજનો સત્સંગ માન ઉપર હતો જેમાં તેમણે સુંદર સૂચનો સૂચવ્યા.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'અમે ભેખ જે લીધો છે જગત કલ્યાણ કાજે ખૂણે-ખૂણે બ્રહ્માંડે પહોંચાડીને રહીશું.'
    },
    answers: [
      'અમે ભેખ જે લીધો છે જગત કલ્યાણ કાજે ખૂણે ખૂણે બ્રહ્માંડે પહોંચાડીને રહીશું.'
    ]
  },
  {
    level: '2',
    question: {
      gu: '૨૦૧૯ માં ૧૧૨ મી જન્મ જયંતી મુંબઈમાં ઉજવાઈ અને જન્મ જયંતી સાથે મુંબઈ ત્રિમંદિર પ્રાણ પ્રતિષ્ઠા નું આયોજન પણ થયું.'
    },
    answers: [
      '૨૦૧૯ માં ૧૧૨ મી જન્મ જયંતી મુંબઈમાં ઉજવાઇ અને જન્મ જયંતી સાથે મુંબઈ ત્રિમંદિર પ્રાણ પ્રતિષ્ઠા નું આયોજન પણ થયું.',
      '2019 માં 112 મી જન્મ જયંતી મુંબઈમાં ઉજવાઇ અને જન્મ જયંતી સાથે મુંબઈ ત્રિમંદિર પ્રાણ પ્રતિષ્ઠા નું આયોજન પણ થયું.',
      '૨૦૧૯ માં ૧૧૨ મી જન્મ જયંતિ મુંબઈમાં ઉજવાઇ અને જન્મ જયંતિ સાથે મુંબઈ ત્રિમંદિર પ્રાણ પ્રતિષ્ઠા નું આયોજન પણ થયું.',
      '2019 માં 112 મી જન્મ જયંતિ મુંબઈમાં ઉજવાઇ અને જન્મ જયંતિ સાથે મુંબઈ ત્રિમંદિર પ્રાણ પ્રતિષ્ઠા નું આયોજન પણ થયું.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'જય સચ્ચિદાનંદ મહાત્માઓ! તમે કાલે અડાલજ શિબિરમાં જો આવવાના હો તો તમારું રજીસ્ટ્રેશન જરૂરથી કરાવી લેજો.'
    },
    answers: [
      'જય સચ્ચિદાનંદ મહાત્માઓ તમે કાલે અડાલજ શિબિરમાં જો આવવાના હો તો તમારું રજીસ્ટ્રેશન જરૂરથી કરાવી લેજો.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'સીમંધર સ્વામી મહાવિદેહ ક્ષેત્રમાં છે અને અમે સ્વામીને રોજ નમસ્કાર કરીએ છીએ.'
    },
    answers: []
  },
  {
    level: '2',
    question: {
      gu: 'દેશ-વિદેશથી ઘણા મહાત્માઓએ પાલીતાણાની જાત્રાનો લાભ લીધો.'
    },
    answers: [
      'દેશ વિદેશથી ઘણા મહાત્માઓએ પાલીતાણા ની જાત્રા નો લાભ લીધો.',
      'દેશ-વિદેશથી ઘણા મહાત્માઓએ પાલીતાણા ની જાત્રા નો લાભ લીધો.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'વીતરાગોનું પેપર દાદાએ ફોડી નાખ્યું એવું દાદા એક જ્ઞાન વાણીમાં કહેતા હતાં.'
    },
    answers: [
      'વીતરાગો નું પેપર દાદાએ ફોડી નાખ્યું એવું દાદા એક જ્ઞાન વાણીમાં કહેતા હતા.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'હું હૃદયપૂર્વક પ્રાર્થના કરું છું કે મારે દાદાનું જ્ઞાન જેમ છે તેમ સમજી મોક્ષે જવું છે.'
    },
    answers: [
      'હું હૃદયપૂર્વક પ્રાર્થના કરું છું કે મારે દાદા નું જ્ઞાન જેમ છે તેમ સમજી મોક્ષે જવું છે.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'હે અંબા માં! અમે અંબાના એકના એક લાલ અંબાલાલની ચિઠ્ઠી લઈને આવ્યા છીએ.'
    },
    answers: [
      'હે અંબા માં અમે અંબાના એક ના એક લાલ અંબાલાલ ની ચિઠ્ઠી લઈને આવ્યા છીએ.',
      'હે અંબા માં અમે અંબાના એકના એક લાલ અંબાલાલ ની ચિઠ્ઠી લઈને આવ્યા છીએ.',
      'હે અંબા માં અમે અંબાના એકના એક લાલ અંબાલાલની ચિઠ્ઠી લઈને આવ્યા છીએ.',
      'હે અંબા માં અમે અંબાના એક ના એક લાલ અંબાલાલની ચિઠ્ઠી લઈને આવ્યા છીએ.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'મારું નામ ચંદુભાઈ છે અને દાદા એમની વાણીમાં ઘણીવાર ચંદુભાઈ નામનો પ્રયોગ કરે છે.'
    },
    answers: []
  },
  {
    level: '2',
    question: {
      gu: 'દાદા ભગવાનની સાક્ષીએ હું સીમંધર સ્વામીને અત્યંત ભક્તિપૂર્વક નમસ્કાર કરું છું.'
    },
    answers: []
  },
  {
    level: '2',
    question: {
      gu: 'પૂજ્ય નીરુમા અને પૂજ્ય દીપકભાઈ ના અથાગ પ્રયત્નથી આજે દાદાની વાણી વિડિયો અને ઓડિયો રૂપે ઉપલબ્ધ છે.'
    },
    answers: []
  },
  {
    level: '2',
    question: {
      gu: 'હું માહિતી કેન્દ્રમાંથી ચંદુભાઈ, તમે કાલે સત્સંગમાં હાજર રહેવાના છો?'
    },
    answers: [
      'હું માહિતી કેન્દ્રમાંથી ચંદુભાઈ તમે કાલે સત્સંગમાં હાજર રહેવાના છો.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'આજ ભવમાં બ્રહ્મચર્ય સિદ્ધ થાય એવી ભાવના હું ભાવ્યા જ કરતો હોઉં છું.'
    },
    answers: []
  },
  {
    level: '2',
    question: {
      gu: 'દાદાને ૧૯૫૮ માં જ્ઞાન પ્રાપ્ત થયું પછી ૩૦ વર્ષ સુધી જ્ઞાનની લ્હાણી કરી શક્યા.'
    },
    answers: [
      'દાદા ને 1958 માં જ્ઞાન પ્રાપ્ત થયું પછી 30 વર્ષ સુધી જ્ઞાનની લહાણી કરી શક્યા.',
      'દાદાને 1958 માં જ્ઞાન પ્રાપ્ત થયું પછી 30 વર્ષ સુધી જ્ઞાનની લહાણી કરી શક્યા.',
      'દાદા ને 1958 માં જ્ઞાન પ્રાપ્ત થયું પછી 30 વર્ષ સુધી જ્ઞાનની લાણી કરી શક્યા.',
      'દાદાને 1958 માં જ્ઞાન પ્રાપ્ત થયું પછી 30 વર્ષ સુધી જ્ઞાનની લાણી કરી શક્યા.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'પુદગલનો એક પણ ગુણ મારામાં નથી અને મારો એક પણ ગુણ પુદગલમાં નથી.'
    },
    answers: [
      'પુદગલ નો એક પણ ગુણ મારામાં નથી અને મારો એક પણ ગુણ પુદગલ માં નથી.'
    ]
  },
  {
    level: '2',
    question: {
      gu: 'હું આજે દ્રઢ નિશ્ચય કરું છું કે દાદાએ આપેલી પાંચ આજ્ઞામાં જ રહીશ.'
    },
    answers: [
      'હું આજે દ્રઢ નિશ્ચય કરું છું કે દાદાએ આપેલી પાંચ આજ્ઞા માં જ રહીશ.'
    ]
  }
]
export default listenSpeak
