<template>
  <div>
    <v-breadcrumbs
      v-if="!isHome"
      :items="breadCrumbItems"
      divider=">"
      class="pa-1 pl-sm-10 pl-4 mt-4"
    ></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.name === 'Home'
    },

    breadCrumbItems() {
      const paths = this.$route.fullPath.split('/')
      const crumbs = []
      const excludeHref = ['practice', 'quiz']
      let tempPath = ''
      paths.forEach((p, index) => {
        if (this.breadCrumbConfig[p]) {
          crumbs.push(this.breadCrumbConfig[p])
        } else if (
          paths.includes('practice') &&
          index == 2 &&
          index < paths.length - 1
        ) {
          crumbs.push(this.buildBreadCrumbForCategory(p))
        } else if (paths.includes('practice') && index == 3) {
          crumbs.push(this.buildBreadCrumbForCategoryPage(paths[2], paths[3]))
        } else {
          tempPath += '/' + p
          crumbs.push({
            text: this.$t(decodeURI(p)),
            disabled: false,
            href:
              index == paths.length - 1
                ? ''
                : excludeHref.includes(p)
                ? ''
                : tempPath
          })
        }
      })
      return crumbs
    }
  },
  methods: {
    buildBreadCrumbForCategoryPage(categoryId, pageId) {
      const title = this.$store.state.practicePlayPages.find(
        (p) => p.categoryId === categoryId && p.id === pageId
      ).name[this.$i18n.locale]
      return { text: title, disabled: false, href: '' }
    },
    buildBreadCrumbForCategory(categoryId) {
      const title = this.$store.state.practicePlayCategories.find(
        (p) => p.id === categoryId
      ).name[this.$i18n.locale]
      return { text: title, disabled: false, href: `/practice/${categoryId}` }
    }
  },
  data() {
    return {
      breadCrumbConfig: {
        '': { text: this.$t('Home'), disabled: false, href: '/' },
        practice: { text: this.$t('practice'), disabled: false, href: '' },
        quiz: { text: this.$t('quiz'), disabled: false, href: '' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-breadcrumbs li {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
</style>
