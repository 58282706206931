const wordList = {
  "level1": {
    કલમ: {
      eng_guj: 'kalam',
      hindi: 'कलम',
      english: 'pen',
      hint: ''
    },
    કપટ: {
      eng_guj: 'kapat',
      hindi: 'धोखा देना, छल करना',
      english: 'deceit',
      hint: ''
    },
    કથન: {
      eng_guj: 'kathan',
      hindi: 'बयान',
      english: 'saying',
      hint: ''
    },
    કસરત: {
      eng_guj: 'kasrat',
      hindi: 'शारीरिक व्यायाम',
      english: 'physical exercise ',
      hint: ''
    },
    ખબર: {
      eng_guj: 'khabar',
      hindi: 'खबर, जानकारी',
      english: 'information ',
      hint: ''
    },
    ખટમલ: {
      eng_guj: 'khatmal',
      hindi: 'खटमल',
      english: 'bedbug',
      hint: ''
    },
    ખાસ: {
      eng_guj: 'khaas',
      hindi: 'खास, विशेष',
      english: 'special',
      hint: ''
    },
    ખાનદાન: {
      eng_guj: 'khandaan',
      hindi: 'सज्जन, प्रतिष्ठित',
      english: 'of a noble family',
      hint: ''
    },
    ગરજ: {
      eng_guj: 'garaj',
      hindi: 'ज़रूरत',
      english: 'need, self-interest',
      hint: ''
    },
    ગરમ: {
      eng_guj: 'garam',
      hindi: 'गर्म',
      english: 'hot',
      hint: ''
    },
    ગળપણ: {
      eng_guj: 'galpan',
      hindi: 'मिठास',
      english: 'sweetness',
      hint: ''
    },
    ગામ: {
      eng_guj: 'gaam',
      hindi: 'गाँव',
      english: 'village',
      hint: ''
    },
    ઘન: {
      eng_guj: 'ghan',
      hindi: 'ठोस',
      english: 'unbroken and dense',
      hint: ''
    },
    ઘડતર: {
      eng_guj: 'ghadatar',
      hindi: 'गढ़ाई, प्रशिक्षण',
      english: 'molding',
      hint: ''
    },
    ઘર: {
      eng_guj: 'ghar',
      hindi: 'घर',
      english: 'home',
      hint: ''
    },
    ઘડપણ: {
      eng_guj: 'ghadpan',
      hindi: 'वृद्धावस्था',
      english: 'old age',
      hint: ''
    },
    ઘાટ: {
      eng_guj: 'ghaat',
      hindi: 'युक्ति करना, ताक में रहना',
      english: 'hidden agenda',
      hint: ''
    },
    ઘમંડ: {
      eng_guj: 'ghamand',
      hindi: 'घमंड',
      english: 'arrogance',
      hint: ''
    },
    ચરણ: {
      eng_guj: 'charan',
      hindi: 'चरण, पैर',
      english: 'lotus feet',
      hint: ''
    },
    ચમક: {
      eng_guj: 'chamak',
      hindi: 'चमक, तेज',
      english: 'brightness',
      hint: ''
    },
    ચટણી: {
      eng_guj: 'chatni',
      hindi: 'चटनी',
      english: 'sauce',
      hint: ''
    },
    છત: {
      eng_guj: 'chhat',
      hindi: 'छत',
      english: 'roof',
      hint: ''
    },
    છતાં: {
      eng_guj: 'chhatan',
      hindi: 'हालांकि',
      english: 'however',
      hint: ''
    },
    છળ: {
      eng_guj: 'chhal',
      hindi: 'छल (कपट)',
      english: 'fraud',
      hint: ''
    },
    છાશ: {
      eng_guj: 'chhash',
      hindi: 'छाछ',
      english: 'buttermilk',
      hint: ''
    },
    જપ: {
      eng_guj: 'jap',
      hindi: 'जाप, नाम स्मरण',
      english: 'chanting',
      hint: ''
    },
    જગત: {
      eng_guj: 'jagat',
      hindi: 'विश्व, जगत',
      english: 'the world',
      hint: ''
    },
    જડ: {
      eng_guj: 'jad',
      hindi: 'निर्जीव, अचेतन',
      english: 'inanimate',
      hint: ''
    },
    જમણ: {
      eng_guj: 'jaman',
      hindi: 'भोजन',
      english: 'meal',
      hint: ''
    },
    જમા: {
      eng_guj: 'jamaa',
      hindi: 'जमा',
      english: 'credited',
      hint: ''
    },
    ઝટપટ: {
      eng_guj: 'jhatpat',
      hindi: 'तुरंत, फटाफट',
      english: 'instant',
      hint: ''
    },
    ઝડપ: {
      eng_guj: 'jhadap',
      hindi: 'गति',
      english: 'speed',
      hint: ''
    },
    ઝાડ: {
      eng_guj: 'jhaad',
      hindi: 'पेड़',
      english: 'a tree',
      hint: ''
    },
    ઝંઝટ: {
      eng_guj: 'jhanjhat',
      hindi: 'मुसीबत, झंझट',
      english: 'trouble',
      hint: ''
    },
    ટપાલ: {
      eng_guj: 'tapaal',
      hindi: 'पोस्ट, मेल',
      english: 'post',
      hint: ''
    },
    ટેવ: {
      eng_guj: 'tev',
      hindi: 'आदत',
      english: 'habit',
      hint: ''
    },
    ટકોર: {
      eng_guj: 'takor',
      hindi: 'टोकना, संकेत देना',
      english: 'cautionary hint',
      hint: ''
    },
    ટીકા: {
      eng_guj: 'teeka',
      hindi: 'गुण दोष की समालोचना',
      english: 'criticism',
      hint: ''
    },
    ઠગ: {
      eng_guj: 'thag',
      hindi: 'ठग, चोर',
      english: 'to cheat',
      hint: ''
    },
    ઠપકો: {
      eng_guj: 'thapko',
      hindi: 'फटकार, डांट',
      english: 'to scold',
      hint: ''
    },
    ઠંડક: {
      eng_guj: 'thandak',
      hindi: 'शीतलता, शांति',
      english: 'cooling',
      hint: ''
    },
    ઠેરઠેર: {
      eng_guj: 'ther ther',
      hindi: 'हर जगह',
      english: 'everywhere',
      hint: ''
    },
    ડર: {
      eng_guj: 'dar',
      hindi: 'डर',
      english: 'fear',
      hint: ''
    },
    ડગમગ: {
      eng_guj: 'dagmag',
      hindi: 'डामाडोल, अस्थिर',
      english: 'unstable',
      hint: ''
    },
    ડહાપણ: {
      eng_guj: 'dahapan',
      hindi: 'बुद्धिमत्ता, समझ',
      english: 'wisdom',
      hint: ''
    },
    ઢબ: {
      eng_guj: 'dhab',
      hindi: 'अंदाज़',
      english: 'style',
      hint: ''
    },
    ઢગ: {
      eng_guj: 'dhag',
      hindi: 'ढेर',
      english: 'heap',
      hint: ''
    },
    ઢાલ: {
      eng_guj: 'dhaal',
      hindi: 'ढाल',
      english: 'shield',
      hint: ''
    },
    ઢમઢમ: {
      eng_guj: 'dhamdham',
      hindi: 'ढमढम - ढोल की ताल',
      english: 'sound as of drum',
      hint: ''
    },
    મરણ: {
      eng_guj: 'marna',
      hindi: 'मृत्यु',
      english: 'death',
      hint: ''
    },
    ભણ: {
      eng_guj: 'bhan',
      hindi: 'पढ़ाई करना',
      english: 'to study',
      hint: ''
    },
    પણ: {
      eng_guj: 'pan',
      hindi: 'भी, परंतु',
      english: 'also, but',
      hint: ''
    },
    તન: {
      eng_guj: 'tan',
      hindi: 'शरीर',
      english: 'body',
      hint: ''
    },
    તપ: {
      eng_guj: 'tap',
      hindi: 'तप',
      english: 'penance',
      hint: ''
    },
    તક: {
      eng_guj: 'tak',
      hindi: 'अवसर',
      english: 'opportunity',
      hint: ''
    },
    તરસ: {
      eng_guj: 'taras',
      hindi: 'प्यास',
      english: 'thirst',
      hint: ''
    },
    તડપ: {
      eng_guj: 'tadap',
      hindi: 'लालसा, तृष्णा',
      english: 'longing',
      hint: ''
    },
    તલ: {
      eng_guj: 'tal',
      hindi: 'तिल',
      english: 'sesame',
      hint: ''
    },
    તરત: {
      eng_guj: 'tarat',
      hindi: 'तुरंत',
      english: 'at once',
      hint: ''
    },
    તમામ: {
      eng_guj: 'tamaam',
      hindi: 'सभी',
      english: 'all',
      hint: ''
    },
    તાવ: {
      eng_guj: 'taav',
      hindi: 'बुखार',
      english: 'fever',
      hint: ''
    },
    થર: {
      eng_guj: 'thar',
      hindi: 'परत',
      english: 'layer',
      hint: ''
    },
    થનગન: {
      eng_guj: 'thangan',
      hindi: 'उत्तेजित होना, नाच',
      english: 'to get excited',
      hint: ''
    },
    થડ: {
      eng_guj: 'thad',
      hindi: 'पेड़ का तना',
      english: 'trunk of a tree',
      hint: ''
    },
    થાક: {
      eng_guj: 'thaak',
      hindi: 'थकान',
      english: 'fatigue',
      hint: ''
    },
    થાળી: {
      eng_guj: 'thali',
      hindi: 'थाली, खाने की प्लेट',
      english: 'dinner plate',
      hint: ''
    },
    દમ: {
      eng_guj: 'dam',
      hindi: 'सांस, जान',
      english: 'breath',
      hint: ''
    },
    દર: {
      eng_guj: 'dar',
      hindi: 'दर; हर एक',
      english: 'rate; every',
      hint: ''
    },
    દસ: {
      eng_guj: 'das',
      hindi: 'दस',
      english: 'ten',
      hint: ''
    },
    દરકાર: {
      eng_guj: 'darkar',
      hindi: 'देखभाल',
      english: 'care',
      hint: ''
    },
    દાનત: {
      eng_guj: 'daanat',
      hindi: 'इरादा',
      english: 'intention',
      hint: ''
    },
    ધન: {
      eng_guj: 'dhan',
      hindi: 'धन, संपत्ति',
      english: 'money',
      hint: ''
    },
    ધગશ: {
      eng_guj: 'dhagash',
      hindi: 'जोश, उत्साह',
      english: 'dedication',
      hint: ''
    },
    ધારણ: {
      eng_guj: 'dhaaran',
      hindi: 'धारण करना',
      english: 'absorb',
      hint: ''
    },
    ધડક: {
      eng_guj: 'dhadak',
      hindi: 'धड़कना',
      english: 'beating',
      hint: ''
    },
    ધડ: {
      eng_guj: 'dhad',
      hindi: 'तना, धड़',
      english: 'trunk',
      hint: ''
    },
    ધવલ: {
      eng_guj: 'dhaval',
      hindi: 'सफ़ेद',
      english: 'white',
      hint: ''
    },
    નકલ: {
      eng_guj: 'nakal',
      hindi: 'नकल',
      english: 'copy',
      hint: ''
    },
    નગર: {
      eng_guj: 'nagar',
      hindi: 'नगर, शहर',
      english: 'a city',
      hint: ''
    },
    નમન: {
      eng_guj: 'naman',
      hindi: 'प्रणाम',
      english: 'bowing in reverence',
      hint: ''
    },
    નખ: {
      eng_guj: 'nakh',
      hindi: 'नाखून',
      english: 'a nail',
      hint: ''
    },
    નવ: {
      eng_guj: 'nav',
      hindi: 'नौ',
      english: 'nine',
      hint: ''
    },
    નસ: {
      eng_guj: 'nas',
      hindi: 'नस',
      english: 'vein',
      hint: ''
    },
    નજર: {
      eng_guj: 'najar',
      hindi: 'नज़र',
      english: 'sight',
      hint: ''
    },
    નયન: {
      eng_guj: 'nayan',
      hindi: 'आँख',
      english: 'eye',
      hint: ''
    },
    નાશ: {
      eng_guj: 'naash',
      hindi: 'विनाश',
      english: 'destruction',
      hint: ''
    },
    પદ: {
      eng_guj: 'pad',
      hindi: 'पद, ज्ञान भक्ति के गीत',
      english: 'status; spiritual song',
      hint: ''
    },
    પળ: {
      eng_guj: 'pal',
      hindi: 'पल, क्षण',
      english: 'moment',
      hint: ''
    },
    પરખ: {
      eng_guj: 'parakh',
      hindi: 'जांच',
      english: 'screening',
      hint: ''
    },
    પરમ: {
      eng_guj: 'param',
      hindi: 'पूर्ण, सर्वोच्च',
      english: 'ultimate',
      hint: ''
    },
    પક્ષ: {
      eng_guj: 'paksh',
      hindi: 'पक्ष',
      english: 'side',
      hint: ''
    },
    પગ: {
      eng_guj: 'pag',
      hindi: 'पैर',
      english: 'leg',
      hint: ''
    },
    પડ: {
      eng_guj: 'pad',
      hindi: 'स्तर',
      english: 'layer',
      hint: ''
    },
    પવન: {
      eng_guj: 'pawan',
      hindi: 'पवन',
      english: 'wind',
      hint: ''
    },
    પથ: {
      eng_guj: 'path',
      hindi: 'रास्ता, राह',
      english: 'road, way',
      hint: ''
    },
    ફરજ: {
      eng_guj: 'faraj',
      hindi: 'कर्तव्य',
      english: 'duty',
      hint: ''
    },
    ફરક: {
      eng_guj: 'farak',
      hindi: 'अंतर',
      english: 'difference',
      hint: ''
    },
    ફળ: {
      eng_guj: 'fal',
      hindi: 'फल',
      english: 'fruit',
      hint: ''
    },
    ફસામણ: {
      eng_guj: 'fasaaman',
      hindi: 'जाल, फँसाव',
      english: 'being entrapped or deceived',
      hint: ''
    },
    બળ: {
      eng_guj: 'bal',
      hindi: 'बल, ताकत',
      english: 'strength, capacity',
      hint: ''
    },
    બસ: {
      eng_guj: 'bas',
      hindi: 'बस, काफ़ी',
      english: 'bus; enough',
      hint: ''
    },
    બચત: {
      eng_guj: 'bachat',
      hindi: 'बचत, जमा पूंजी',
      english: 'savings',
      hint: ''
    },
    બરકત: {
      eng_guj: 'barkat',
      hindi: 'समृद्धि',
      english: 'gain; success',
      hint: ''
    },
    બરફ: {
      eng_guj: 'baraf',
      hindi: 'बर्फ',
      english: 'ice',
      hint: ''
    },
    બપોર: {
      eng_guj: 'bapor',
      hindi: 'दोपहर',
      english: 'noon',
      hint: ''
    },
    ભજન: {
      eng_guj: 'bhajan',
      hindi: 'भजन',
      english: 'hymn',
      hint: ''
    },
    ભવ: {
      eng_guj: 'bhav',
      hindi: 'भव, जीवन',
      english: 'life',
      hint: ''
    },
    ભણતર: {
      eng_guj: 'bhantar',
      hindi: 'शिक्षा',
      english: 'education',
      hint: ''
    },
    ભય: {
      eng_guj: 'bhay',
      hindi: 'डर, भय',
      english: 'fear',
      hint: ''
    },
    ભાગ: {
      eng_guj: 'bhaag',
      hindi: 'भाग, हिस्सा',
      english: 'part, share',
      hint: ''
    },
    ભાન: {
      eng_guj: 'bhaan',
      hindi: 'भान, अहसास',
      english: 'consciousness',
      hint: ''
    },
    ભાર: {
      eng_guj: 'bhaar',
      hindi: 'बोझ; वज़न',
      english: 'burden; weight',
      hint: ''
    },
    મન: {
      eng_guj: 'man',
      hindi: 'मन',
      english: 'mind',
      hint: ''
    },
    મત: {
      eng_guj: 'mat',
      hindi: 'मत',
      english: 'vote',
      hint: ''
    },
    મગજ: {
      eng_guj: 'magaj',
      hindi: 'दिमाग',
      english: 'brain',
      hint: ''
    },
    મદદ: {
      eng_guj: 'madad',
      hindi: 'मदद',
      english: 'help',
      hint: ''
    },
    મફત: {
      eng_guj: 'mafat',
      hindi: 'मुफ्त',
      english: 'free of cost',
      hint: ''
    },
    માણસ: {
      eng_guj: 'maanas',
      hindi: 'व्यक्ति, मनुष्य',
      english: 'human being',
      hint: ''
    },
    માન: {
      eng_guj: 'maan',
      hindi: 'मान',
      english: 'fame',
      hint: ''
    },
    માયા: {
      eng_guj: 'maya',
      hindi: 'माया, ममता',
      english: 'illusion',
      hint: ''
    },
    યશ: {
      eng_guj: 'yash',
      hindi: 'यश, कीर्ति',
      english: 'credit, fame',
      hint: ''
    },
    યજ્ઞ: {
      eng_guj: 'yagna',
      hindi: 'यज्ञ',
      english: 'sacrificial fire ritual; sacrifice',
      hint: ''
    },
    યાદ: {
      eng_guj: 'yaad',
      hindi: 'याद',
      english: 'memory',
      hint: ''
    },
    રમ: {
      eng_guj: 'ram',
      hindi: 'खेलना',
      english: 'to play',
      hint: ''
    },
    રકમ: {
      eng_guj: 'rakam',
      hindi: 'राशि, रकम',
      english: 'amount, sum',
      hint: ''
    },
    રસમ: {
      eng_guj: 'rasam',
      hindi: 'रसम, रिवाज',
      english: 'custom',
      hint: ''
    },
    રસ: {
      eng_guj: 'ras',
      hindi: 'रस, रूचि',
      english: 'interest',
      hint: ''
    },
    રટન: {
      eng_guj: 'ratan',
      hindi: 'दोहराना',
      english: 'to repeat',
      hint: ''
    },
    રક્ષણ: {
      eng_guj: 'rakshan',
      hindi: 'सुरक्षा, रक्षा',
      english: 'protection',
      hint: ''
    },
    લક્ષણ: {
      eng_guj: 'lakshan',
      hindi: 'लक्षण',
      english: 'symptom',
      hint: ''
    },
    લક્ષ: {
      eng_guj: 'laksh',
      hindi: 'लक्ष्य',
      english: 'aim',
      hint: ''
    },
    લત: {
      eng_guj: 'lat',
      hindi: 'लत, कुटेव',
      english: 'addiction',
      hint: ''
    },
    લય: {
      eng_guj: 'lay',
      hindi: 'विनाश, लय',
      english: 'destruction, rhythm',
      hint: ''
    },
    લખ: {
      eng_guj: 'lakha',
      hindi: 'लाख, लिख',
      english: 'lakh or one lakh, to write',
      hint: ''
    },
    લગન: {
      eng_guj: 'lagan',
      hindi: 'लगन, से जुड़ा',
      english: 'connected with',
      hint: ''
    },
    લાલચ: {
      eng_guj: 'lalach',
      hindi: 'लालच',
      english: 'greed',
      hint: ''
    },
    વડ: {
      eng_guj: 'vad',
      hindi: 'बरगद का पेड़',
      english: 'a banyan tree',
      hint: ''
    },
    વય: {
      eng_guj: 'vaya',
      hindi: 'उम्र',
      english: 'age',
      hint: ''
    },
    વરસ: {
      eng_guj: 'varas',
      hindi: 'साल',
      english: 'year',
      hint: ''
    },
    વચન: {
      eng_guj: 'vachan',
      hindi: 'वादा',
      english: 'promise',
      hint: ''
    },
    વળતર: {
      eng_guj: 'valatar',
      hindi: 'नुकसान भरपाई',
      english: 'compensation',
      hint: ''
    },
    વમળ: {
      eng_guj: 'vamal',
      hindi: 'भँवर',
      english: 'whirlpool',
      hint: ''
    },
    વાત: {
      eng_guj: 'vaat',
      hindi: 'बात',
      english: 'talk',
      hint: ''
    },
    વખત: {
      eng_guj: 'vakhat',
      hindi: 'समय',
      english: 'time',
      hint: ''
    },
    વપરાશ: {
      eng_guj: 'vaprash',
      hindi: 'उपयोग',
      english: 'consumption',
      hint: ''
    },
    વરાળ: {
      eng_guj: 'varaal',
      hindi: 'भाप',
      english: 'vapor',
      hint: ''
    },
    વાચક: {
      eng_guj: 'vaachak',
      hindi: 'वाचक, पढ़ने वाला',
      english: 'reader',
      hint: ''
    },
    શરણ: {
      eng_guj: 'sharan',
      hindi: 'शरण, आत्मसमर्पण',
      english: 'refuge, to surrender ',
      hint: ''
    },
    શક: {
      eng_guj: 'shak',
      hindi: 'शक, संदेह',
      english: 'suspicion',
      hint: ''
    },
    શક્ય: {
      eng_guj: 'shakya',
      hindi: 'संभव',
      english: 'possible',
      hint: ''
    },
    શપથ: {
      eng_guj: 'shapath',
      hindi: 'शपथ, कसम',
      english: 'oath',
      hint: ''
    },
    શાસન: {
      eng_guj: 'shaasan',
      hindi: 'शासन, राज',
      english: 'governance',
      hint: ''
    },
    ષટ્: {
      eng_guj: 'shut',
      hindi: 'छः',
      english: 'six',
      hint: ''
    },
    ષટ્કોણ: {
      eng_guj: 'shutkon',
      hindi: 'षट्कोण',
      english: 'hexagon',
      hint: ''
    },
    શેષ: {
      eng_guj: 'shesh',
      hindi: 'शेष',
      english: 'remaining',
      hint: ''
    },
    સહન: {
      eng_guj: 'sahan',
      hindi: 'सहना',
      english: 'bear',
      hint: ''
    },
    સડક: {
      eng_guj: 'sadak',
      hindi: 'सड़क, रास्ता',
      english: 'road',
      hint: ''
    },
    સતત: {
      eng_guj: 'satat',
      hindi: 'निरंतर',
      english: 'continuous',
      hint: ''
    },
    સમય: {
      eng_guj: 'samay',
      hindi: 'समय, काल',
      english: 'time',
      hint: ''
    },
    સરળ: {
      eng_guj: 'saral',
      hindi: 'सरल, सीधा',
      english: 'easy',
      hint: ''
    },
    સરસ: {
      eng_guj: 'saras',
      hindi: 'अच्छा',
      english: 'good',
      hint: ''
    },
    સમજણ: {
      eng_guj: 'samajan',
      hindi: 'समझ',
      english: 'understanding',
      hint: ''
    },
    સમરસ: {
      eng_guj: 'samras',
      hindi: 'सभी छः स्वादों का संतुलन',
      english: 'balance of all six tastes',
      hint: ''
    },
    સગવડ: {
      eng_guj: 'sagvad',
      hindi: 'सुविधाएँ',
      english: 'convenience',
      hint: ''
    },
    સાર: {
      eng_guj: 'saar',
      hindi: 'सारांश, अर्थ',
      english: 'essence',
      hint: ''
    },
    સવાર: {
      eng_guj: 'savaar',
      hindi: 'सुबह',
      english: 'morning',
      hint: ''
    },
    હઠ: {
      eng_guj: 'hatha',
      hindi: 'ज़िद',
      english: 'stubbornness',
      hint: ''
    },
    હક: {
      eng_guj: 'hak',
      hindi: 'हक, सही',
      english: 'right',
      hint: ''
    },
    હદ: {
      eng_guj: 'had',
      hindi: 'हद, सीमा',
      english: 'boundary',
      hint: ''
    },
    હરખ: {
      eng_guj: 'harakh',
      hindi: 'खुशी, आनन्द',
      english: 'joy',
      hint: ''
    },
    હવન: {
      eng_guj: 'havan',
      hindi: 'हवन',
      english: 'offering oblation into fire',
      hint: ''
    },
    હળદર: {
      eng_guj: 'haldar',
      hindi: 'हल्दी',
      english: 'turmeric',
      hint: ''
    },
    હરણ: {
      eng_guj: 'haran',
      hindi: 'हिरन',
      english: 'deer',
      hint: ''
    },
    હજમ: {
      eng_guj: 'hazam',
      hindi: 'हज़म, पाचन',
      english: 'to digest',
      hint: ''
    },
    નળ: {
      eng_guj: 'nal',
      hindi: 'नल',
      english: 'tap',
      hint: ''
    },
    કમળ: {
      eng_guj: 'kamal',
      hindi: 'कमल का फूल',
      english: 'lotus',
      hint: ''
    },
    બળતણ: {
      eng_guj: 'baltan',
      hindi: 'जलाऊ लकड़ी, ईंधन',
      english: 'firewood, fuel',
      hint: ''
    },
    ક્ષય: {
      eng_guj: 'kshya',
      hindi: 'विनाश, क्षय',
      english: 'destruction',
      hint: ''
    },
    ક્ષણ: {
      eng_guj: 'kshan',
      hindi: 'पल',
      english: 'moment',
      hint: ''
    },
    રક્ષક: {
      eng_guj: 'rakshak',
      hindi: 'रक्षक',
      english: 'protector',
      hint: ''
    },
    ક્ષમા: {
      eng_guj: 'kshama',
      hindi: 'माफ़ी, क्षमा',
      english: 'forgiveness',
      hint: ''
    },
    જ્ઞાન: {
      eng_guj: 'gnan',
      hindi: 'ज्ञान, जानना',
      english: 'knowledge',
      hint: ''
    },
    જ્ઞાતા: {
      eng_guj: 'gnata',
      hindi: 'ज्ञाता, जानने वाला',
      english: 'the knower',
      hint: ''
    }
  },
  level2: {
    અહમ: {
      eng_guj: 'Aham',
      hindi: 'अहम्, मैं (अहंकार)',
      english: 'Ego',
      hint: ''
    },
    અથડામણ: {
      eng_guj: 'athdaaman',
      hindi: 'टकराव',
      english: 'clash',
      hint: ''
    },
    અવતાર: {
      eng_guj: 'Avataar',
      hindi: 'अवतार, जन्म',
      english: 'Incarnation ,birth',
      hint: ''
    },
    અવસર: {
      eng_guj: 'avasar',
      hindi: 'मौका, अवसर',
      english: 'opportunity ,occasion',
      hint: ''
    },
    અનુભવ: {
      eng_guj: 'anubhav',
      hindi: 'अनुभव',
      english: 'experience',
      hint: ''
    },
    કળશ: {
      eng_guj: 'Kalash',
      hindi: 'कलश, घड़ा',
      english: 'The holy pot; water pot',
      hint: ''
    },
    કલમ: {
      eng_guj: 'kalam',
      hindi: 'कलम, पेन',
      english: 'Pen',
      hint: ''
    },
    ખમણ: {
      eng_guj: 'khaman',
      hindi: 'ढोकले का एक प्रकार',
      english: 'one type of dhokla (snack item)',
      hint: ''
    },
    ખડગ: {
      eng_guj: 'khadag',
      hindi: 'खड़ग, तलवार',
      english: 'Sword of goddess mother',
      hint: ''
    },
    ગમન: {
      eng_guj: 'gaman',
      hindi: 'गमन, जाना',
      english: 'Movement, departure',
      hint: ''
    },
    આચાર: {
      eng_guj: 'aachaar',
      hindi: 'आचार',
      english: 'conduct',
      hint: ''
    },
    આજ્ઞા: {
      eng_guj: 'Aagna',
      hindi: 'आज्ञा',
      english: "dada's five Aagna ",
      hint: ''
    },
    આદિ: {
      eng_guj: 'aadi',
      hindi: 'आदि, अनंत',
      english: 'the beginning; origin',
      hint: ''
    },
    આધાર: {
      eng_guj: 'aadhaar',
      hindi: 'आधार',
      english: 'support',
      hint: ''
    },
    આનંદ: {
      eng_guj: 'aanand',
      hindi: 'आनन्द, हर्ष',
      english: 'happiness , joy',
      hint: ''
    },
    સાર: {
      eng_guj: 'saar',
      hindi: 'सारांश',
      english: 'essence, gist',
      hint: ''
    },
    ભાવ: {
      eng_guj: 'bhaav',
      hindi: 'भाव',
      english: 'intention',
      hint: ''
    },
    દાખલા: {
      eng_guj: 'daakhala',
      hindi: 'उदाहरण',
      english: 'example',
      hint: ''
    },
    કાળજી: {
      eng_guj: 'kaaljee',
      hindi: 'देखभाल',
      english: 'care',
      hint: ''
    },
    લાગણી: {
      eng_guj: 'laagni',
      hindi: 'स्नेह',
      english: 'affection',
      hint: ''
    },
    ઇંગ્લિશ: {
      eng_guj: 'Inglish',
      hindi: 'अंग्रेजी',
      english: 'English',
      hint: ''
    },
    ઇંચ: {
      eng_guj: 'inch',
      hindi: 'इंच, माप',
      english: 'to measure the length',
      hint: ''
    },
    ઇનામ: {
      eng_guj: 'inam',
      hindi: 'इनाम',
      english: 'gift, reward',
      hint: ''
    },
    ઇર્ષા: {
      eng_guj: 'irsha',
      hindi: 'ईर्ष्या, जलन',
      english: 'jealousy',
      hint: ''
    },
    ઇતિહાસ: {
      eng_guj: 'itihaas',
      hindi: 'इतिहास',
      english: 'history',
      hint: ''
    },
    મંઝિલ: {
      eng_guj: 'manzil',
      hindi: 'मंजिल, लक्ष्य',
      english: 'Destination',
      hint: ''
    },
    ટિકિટ: {
      eng_guj: 'ticket',
      hindi: 'टिकट',
      english: 'Ticket',
      hint: ''
    },
    વિરાટ: {
      eng_guj: 'viraat',
      hindi: 'विशाल',
      english: 'huge',
      hint: ''
    },
    રિવાજ: {
      eng_guj: 'rivaaj',
      hindi: 'रिवाज',
      english: 'custom',
      hint: ''
    },
    સ્થિર: {
      eng_guj: 'sthir',
      hindi: 'स्थिर',
      english: 'stable',
      hint: ''
    },
    ઈચ્છા: {
      eng_guj: 'eechchha',
      hindi: 'ईच्छा',
      english: 'wish',
      hint: ''
    },
    ઈશ્વર: {
      eng_guj: 'eeshwar',
      hindi: 'ईश्वर',
      english: 'God; the lord',
      hint: ''
    },
    ઈમાનદાર: {
      eng_guj: 'eemaandaar',
      hindi: 'ईमानदार',
      english: 'honest',
      hint: ''
    },
    ઈશાન: {
      eng_guj: 'Eeshaan',
      hindi: 'ईशान (दिशा)',
      english: 'north-east direction',
      hint: ''
    },
    ઈજા: {
      eng_guj: 'eeja',
      hindi: 'चोट',
      english: 'injury',
      hint: ''
    },
    માડી: {
      eng_guj: 'maadi',
      hindi: 'माँ',
      english: 'Mother',
      hint: ''
    },
    ઢીંગલી: {
      eng_guj: 'dhingali',
      hindi: 'गुड़िया',
      english: 'doll',
      hint: ''
    },
    ઝીણવટ: {
      eng_guj: 'jheenvat',
      hindi: 'विस्तार',
      english: 'minuteness, detail',
      hint: ''
    },
    ધીમી: {
      eng_guj: 'dheemee',
      hindi: 'धीरे',
      english: 'slow',
      hint: ''
    },
    તીવ્ર: {
      eng_guj: 'teevra',
      hindi: 'तीव्र',
      english: 'intense',
      hint: ''
    },
    ઉપકાર: {
      eng_guj: 'upkaar',
      hindi: 'उपकार',
      english: 'obligation',
      hint: ''
    },
    ઉકેલ: {
      eng_guj: 'ukel',
      hindi: 'उपाय, हल',
      english: 'solution; way',
      hint: ''
    },
    ઉપાધિ: {
      eng_guj: 'upaadhi',
      hindi: 'उपाधि, झंझट',
      english: 'trouble , anxiety',
      hint: ''
    },
    ઉદય: {
      eng_guj: 'uday',
      hindi: 'उदय, उगना',
      english: 'manifestation , rise',
      hint: ''
    },
    ઉપદેશ: {
      eng_guj: 'updesh',
      hindi: 'उपदेश, बोध',
      english: 'preaching , teaching',
      hint: ''
    },
    ખાણું: {
      eng_guj: 'khaanu',
      hindi: 'खाना',
      english: 'meal ',
      hint: ''
    },
    ગાણું: {
      eng_guj: 'gaanu',
      hindi: 'गाना',
      english: 'sing',
      hint: ''
    },
    ગુણ: {
      eng_guj: 'gun',
      hindi: 'गुण',
      english: 'quality',
      hint: ''
    },
    દુર્લભ: {
      eng_guj: 'durlabh',
      hindi: 'दुर्लभ',
      english: 'difficult to obtain',
      hint: ''
    },
    સુલભ: {
      eng_guj: 'sulabh',
      hindi: 'सुलभ',
      english: 'easily available',
      hint: ''
    },
    ઊંડા: {
      eng_guj: 'oonda',
      hindi: 'गहरा',
      english: 'deep',
      hint: ''
    },
    ઊજવવું: {
      eng_guj: 'oojavvu',
      hindi: 'जश्न मनाना',
      english: 'to celebrate',
      hint: ''
    },
    ઊભું: {
      eng_guj: 'oobhu',
      hindi: 'खड़ा',
      english: 'to stand',
      hint: ''
    },
    ઊંધું: {
      eng_guj: 'oondhu',
      hindi: 'उल्टा',
      english: 'inverted; wrong',
      hint: ''
    },
    દૂર: {
      eng_guj: 'dur',
      hindi: 'दूर',
      english: 'Away, far',
      hint: ''
    },
    ધૂપસળી: {
      eng_guj: 'dhupsali',
      hindi: 'अगरबत्ती',
      english: 'incense stick',
      hint: ''
    },
    નૂતન: {
      eng_guj: 'nutan',
      hindi: 'नूतन, नया',
      english: ' new',
      hint: ''
    },
    નૂર: {
      eng_guj: 'noor',
      hindi: 'नूर, तेज़',
      english: 'Aura of brilliance; lustre',
      hint: ''
    },
    ખૂટે: {
      eng_guj: 'khute',
      hindi: 'कमी',
      english: 'lack',
      hint: ''
    },
    એક: {
      eng_guj: 'Ek',
      hindi: 'एक',
      english: 'one',
      hint: ''
    },
    એકાકાર: {
      eng_guj: 'ekakaar',
      hindi: 'एकाकार, तन्मय',
      english: 'to become one with',
      hint: ''
    },
    એકતા: {
      eng_guj: 'ekta',
      hindi: 'एकता',
      english: 'unity; concord',
      hint: ''
    },
    એટલે: {
      eng_guj: 'etle',
      hindi: 'मतलब',
      english: 'therefore; hence',
      hint: ''
    },
    એલચી: {
      eng_guj: 'Elchi',
      hindi: 'इलायची',
      english: 'cardamom',
      hint: ''
    },
    ફેરફાર: {
      eng_guj: 'ferfaar',
      hindi: 'परिवर्तन',
      english: 'change',
      hint: ''
    },
    હેતુ: {
      eng_guj: 'hetu',
      hindi: 'उद्देश्य',
      english: 'motive, purpose',
      hint: ''
    },
    ટેવ: {
      eng_guj: 'tev',
      hindi: 'आदत',
      english: 'habit',
      hint: ''
    },
    ઠેઠ: {
      eng_guj: 'theth',
      hindi: 'ठेठ',
      english: 'to the end',
      hint: ''
    },
    શેષ: {
      eng_guj: 'shesh',
      hindi: 'शेष',
      english: 'remaining',
      hint: ''
    },
    ઐશ્વર્ય: {
      eng_guj: 'aishwarya',
      hindi: 'ऐश्वर्य, तेज़',
      english: 'Godly qualities; divinity',
      hint: ''
    },
    ઐતિહાસિક: {
      eng_guj: 'aitihaasik',
      hindi: 'ऐतिहासिक',
      english: 'historical',
      hint: ''
    },
    ઐક્ય: {
      eng_guj: 'aikya',
      hindi: 'एकता',
      english: 'oneness',
      hint: ''
    },
    ઐહિક: {
      eng_guj: 'aihik',
      hindi: 'भौतिक',
      english: 'worldly',
      hint: ''
    },
    દૈહિક: {
      eng_guj: 'daihik',
      hindi: 'शारीरिक',
      english: 'physical',
      hint: ''
    },
    મૈથિલી: {
      eng_guj: 'maithili',
      hindi: 'मैथिली (नाम)',
      english: 'name of devi sita, name of a girl',
      hint: ''
    },
    વૈરાગ્ય: {
      eng_guj: 'vairagya',
      hindi: 'वैराग्य',
      english: 'Freedom from attachment to worldly pleasures.',
      hint: ''
    },
    વૈજ્ઞાનિક: {
      eng_guj: 'vaignaanik',
      hindi: 'वैज्ञानिक',
      english: 'scientific',
      hint: ''
    },
    સરવૈયું: {
      eng_guj: 'sarvaiyu',
      hindi: 'चिट्ठा, अंतिम परिणाम',
      english: 'balance sheet; end result',
      hint: ''
    },
    ત્રૈલોક્ય: {
      eng_guj: 'trailokya',
      hindi: 'त्रैलोक्य',
      english: 'three worlds',
      hint: ''
    },
    ઓળંગવું: {
      eng_guj: 'olangvu',
      hindi: 'पार करना, उल्लंघन करना',
      english: 'cross, pass over; disregard',
      hint: ''
    },
    ઓછું: {
      eng_guj: 'ochu',
      hindi: 'कम',
      english: 'less than necessary',
      hint: ''
    },
    ઓરડો: {
      eng_guj: 'ordo',
      hindi: 'कमरा, कक्ष',
      english: 'a room',
      hint: ''
    },
    ઓશીકું: {
      eng_guj: 'oshiku',
      hindi: 'तकिया',
      english: 'a pillow',
      hint: ''
    },
    ઓળખ: {
      eng_guj: 'olakh',
      hindi: 'पहचान',
      english: 'identity',
      hint: ''
    },
    રોફ: {
      eng_guj: 'rof',
      hindi: 'रोब',
      english: 'impression',
      hint: ''
    },
    રોનક: {
      eng_guj: 'Ronak',
      hindi: 'रौनक',
      english: 'pomp, splendour',
      hint: ''
    },
    લોકો: {
      eng_guj: 'loko',
      hindi: 'लोग',
      english: 'people',
      hint: ''
    },
    માવો: {
      eng_guj: 'maavo',
      hindi: 'मावो (खाने की चीज़)',
      english: 'powdered condensed milk',
      hint: ''
    },
    કૂવો: {
      eng_guj: 'kuwo',
      hindi: 'कुआँ',
      english: 'stepwell',
      hint: ''
    },
    ઔષધ: {
      eng_guj: 'aushadh',
      hindi: 'औषध, दवाई',
      english: 'medicine',
      hint: ''
    },
    ઔરંગાબાદ: {
      eng_guj: 'Aurangabaad',
      hindi: 'औरंगाबाद (शहर)',
      english: 'name of city, place where Nirumaa was born',
      hint: ''
    },
    ઔપચારીક: {
      eng_guj: 'aupchaarik',
      hindi: 'औपचारिक',
      english: 'formal',
      hint: ''
    },
    ઔચિત્ય: {
      eng_guj: 'auchitya',
      hindi: 'सभ्यता',
      english: 'fairness, decency',
      hint: ''
    },
    ઔદ્યોગિક: {
      eng_guj: 'audayogik',
      hindi: 'औद्योगिक',
      english: 'industrial',
      hint: ''
    },
    ઔદાર્ય: {
      eng_guj: 'audaarya',
      hindi: 'उदारता',
      english: 'generosity',
      hint: ''
    },
    શૌનક: {
      eng_guj: 'Saunak',
      hindi: 'शौनक (नाम)',
      english: 'name of a boy',
      hint: ''
    },
    શૌર્ય: {
      eng_guj: 'shaurya',
      hindi: 'शौर्य, साहस',
      english: 'heroism, gallantry, intrepidity',
      hint: ''
    },
    શૈલેષ: {
      eng_guj: 'Shailesh',
      hindi: 'शैलेष (नाम)',
      english: 'name of boy',
      hint: ''
    },
    સૌરભ: {
      eng_guj: 'saurabh',
      hindi: 'सुगंध',
      english: 'fragrance, aroma, scent',
      hint: ''
    },
    લૌકિક: {
      eng_guj: 'laukik',
      hindi: 'लौकिक',
      english: 'popular; worldly',
      hint: ''
    },
    અંતિમ: {
      eng_guj: 'antim',
      hindi: 'अंतिम, आखिरी',
      english: 'final, last',
      hint: ''
    },
    અંગૂઠો: {
      eng_guj: 'angutho',
      hindi: 'अंगूठा',
      english: 'thumb',
      hint: ''
    },
    અંધકાર: {
      eng_guj: 'andhkaar',
      hindi: 'अंधेरा',
      english: 'darkness',
      hint: ''
    },
    અંશ: {
      eng_guj: 'ansh',
      hindi: 'अंश, हिस्सा',
      english: 'part; share',
      hint: ''
    },
    અંતરાય: {
      eng_guj: 'antraay',
      hindi: 'बाधाओं',
      english: 'obstacles',
      hint: ''
    },
    ઘમંડ: {
      eng_guj: 'ghamand',
      hindi: 'घमंड',
      english: 'arrogance',
      hint: ''
    },
    છતાં: {
      eng_guj: 'chhata',
      hindi: 'हालांकि',
      english: 'yet, still',
      hint: ''
    },
    ઝંઝટ: {
      eng_guj: 'jhanjhat',
      hindi: 'मुसीबत, झंझट',
      english: 'entanglements',
      hint: ''
    },
    ઠંડક: {
      eng_guj: 'thandak',
      hindi: 'शीतलता, शांति',
      english: 'cold',
      hint: ''
    },
    સંબંધ: {
      eng_guj: 'sambandh',
      hindi: 'संबंध',
      english: 'connection, relation',
      hint: ''
    },
    નમઃ: {
      eng_guj: 'namah',
      hindi: 'नमस्कार, नमन',
      english: 'salutation',
      hint: ''
    },
    શાંતિઃ: {
      eng_guj: 'shanti',
      hindi: 'शांति',
      english: 'peace',
      hint: ''
    },
    ક્રમશઃ: {
      eng_guj: 'kramash',
      hindi: 'क्रमशः, बारी बारी',
      english: 'sequential order',
      hint: ''
    },
    અંતઃકરણ: {
      eng_guj: 'antahkaran',
      hindi: 'अंतःकरण',
      english: 'inner self',
      hint: ''
    },
    દુઃખ: {
      eng_guj: 'dukh',
      hindi: 'दुःख',
      english: 'grief, pain',
      hint: ''
    },
    અંશતઃ: {
      eng_guj: 'ansh',
      hindi: 'अंशतः',
      english: 'partly',
      hint: ''
    },
    અતઃ: {
      eng_guj: 'atah',
      hindi: 'अतः',
      english: 'therefore',
      hint: ''
    }
  },
  level3: {
    ત્રિકાળ: {
      eng_guj: 'trikaal',
      hindi: 'त्रिकाल',
      english: 'past, present, and future',
      hint: ''
    },
    ત્રિમંત્ર: {
      eng_guj: 'trimantra',
      hindi: 'त्रिमंत्र',
      english: 'trimantra',
      hint: ''
    },
    ત્રૈલોક્ય: {
      eng_guj: 'trailokya',
      hindi: 'त्रैलोक्य',
      english: 'three worlds',
      hint: ''
    },
    ગોત્ર: {
      eng_guj: 'gotra',
      hindi: 'गोत्र',
      english: 'Clan; status; family',
      hint: ''
    },
    ત્રાસ: {
      eng_guj: 'traas',
      hindi: 'कष्ट देना',
      english: 'torture, harassment',
      hint: ''
    },
    ત્રણ: {
      eng_guj: 'tran',
      hindi: 'तीन',
      english: 'three',
      hint: ''
    },
    ત્રીજી: {
      eng_guj: 'treeji',
      hindi: 'तीसरी',
      english: 'third',
      hint: ''
    },
    માત્ર: {
      eng_guj: 'maatra',
      hindi: 'मात्र',
      english: 'only, mere',
      hint: ''
    },
    તેત્રીસ: {
      eng_guj: 'tetrees',
      hindi: 'तैंतीस',
      english: 'thirty three',
      hint: ''
    },
    તંત્ર: {
      eng_guj: 'tantra',
      hindi: 'तंत्र',
      english: 'machinery',
      hint: ''
    },
    ત્રિવિધ: {
      eng_guj: 'trividh',
      hindi: 'त्रिविध, तिहरा',
      english: 'triple',
      hint: ''
    },
    શ્રી: {
      eng_guj: 'shree',
      hindi: 'श्री',
      english: 'respected, glory',
      hint: ''
    },
    શ્રમ: {
      eng_guj: 'shram',
      hindi: 'मेहनत',
      english: 'labor, effort',
      hint: ''
    },
    શ્રાવક: {
      eng_guj: 'shraavak',
      hindi: 'श्रावक',
      english: 'devout follower; Jain layman',
      hint: ''
    },
    શ્રેણી: {
      eng_guj: 'shreni',
      hindi: 'श्रृंखला',
      english: 'series',
      hint: ''
    },
    પરિશ્રમ: {
      eng_guj: 'parishram',
      hindi: 'मेहनत',
      english: 'hard work',
      hint: ''
    },
    શ્રીમંત: {
      eng_guj: 'shreemant',
      hindi: 'धनी',
      english: 'rich',
      hint: ''
    },
    શ્રીમાન: {
      eng_guj: 'shreemaan',
      hindi: 'श्रीमान',
      english: 'mister',
      hint: ''
    },
    પૂજ્યશ્રી: {
      eng_guj: 'pujyashree',
      hindi: 'पूज्यश्री, श्रद्धेय',
      english: 'honored',
      hint: ''
    },
    દાદાશ્રી: {
      eng_guj: 'dadashree',
      hindi: 'दादाश्री',
      english: 'dadashree',
      hint: ''
    },
    શ્રીરામ: {
      eng_guj: 'Shree Rama',
      hindi: 'श्रीराम',
      english: 'Shree Rama',
      hint: ''
    },
    દુરુપયોગ: {
      eng_guj: 'durupyog',
      hindi: 'दुरुपयोग',
      english: 'misuse',
      hint: ''
    },
    ગુરુવાર: {
      eng_guj: 'guruvaar',
      hindi: 'गुरुवार',
      english: 'Thursday',
      hint: ''
    },
    ગુરુ: {
      eng_guj: 'guru',
      hindi: 'गुरु',
      english: 'master',
      hint: ''
    },
    પુરુષ: {
      eng_guj: 'purush',
      hindi: 'पुरुष',
      english: 'male',
      hint: ''
    },
    સારું: {
      eng_guj: 'saaru',
      hindi: 'अच्छा',
      english: 'good',
      hint: ''
    },
    તમારું: {
      eng_guj: 'tamaaru',
      hindi: 'तुम्हारा',
      english: 'your',
      hint: ''
    },
    મારુ: {
      eng_guj: 'maaru',
      hindi: 'मेरा',
      english: 'mine',
      hint: ''
    },
    કરુણા: {
      eng_guj: 'karunaa',
      hindi: 'दया',
      english: 'compassion',
      hint: ''
    },
    પૂરું: {
      eng_guj: 'pooru',
      hindi: 'ख़त्म होना',
      english: 'finished',
      hint: ''
    },
    કરુ: {
      eng_guj: 'karu',
      hindi: 'करना',
      english: 'to do',
      hint: ''
    },
    રૂચિ: {
      eng_guj: 'ruchi',
      hindi: 'दिलचस्पी',
      english: 'interest',
      hint: ''
    },
    રૂપ: {
      eng_guj: 'rup',
      hindi: 'रूप',
      english: 'form',
      hint: ''
    },
    રૂપાંતર: {
      eng_guj: 'rupaantar',
      hindi: 'परिवर्तन',
      english: 'transformation',
      hint: ''
    },
    આબરૂ: {
      eng_guj: 'aabru',
      hindi: 'प्रतिष्ठा',
      english: 'reputation',
      hint: ''
    },
    રૂબરૂ: {
      eng_guj: 'rubru',
      hindi: 'आमने सामने',
      english: 'face to face',
      hint: ''
    },
    રૂવાંટી: {
      eng_guj: 'ruvanti',
      hindi: 'रोआँ',
      english: 'pilus',
      hint: ''
    },
    રૂપિયા: {
      eng_guj: 'rupiya',
      hindi: 'रुपये',
      english: 'money',
      hint: ''
    },
    જરૂર: {
      eng_guj: 'jarur',
      hindi: 'ज़रूरत',
      english: 'need',
      hint: ''
    },
    શરૂઆત: {
      eng_guj: 'sharuaat',
      hindi: 'शुरुआत',
      english: 'beginning',
      hint: ''
    },
    સ્વરૂપ: {
      eng_guj: 'swaroop',
      hindi: 'स्वरूप',
      english: 'form',
      hint: ''
    },
    શક્તિરૂપે: {
      eng_guj: 'shaktiroope',
      hindi: 'शक्तिशाली रूप में',
      english: 'powerfully',
      hint: ''
    },
    મદદરૂપ: {
      eng_guj: 'madadroop',
      hindi: 'उपयोगी',
      english: 'helpful',
      hint: ''
    },
    ૐ: {
      eng_guj: 'ohm',
      hindi: 'ॐ',
      english: 'a symbol of hinduism',
      hint: ''
    },
    ઋષભદેવ: {
      eng_guj: 'Rushabdev',
      hindi: 'ऋषभदेव',
      english: 'The first tirthankar of the current half-cycle of time.',
      hint: ''
    },
    ઋતુ: {
      eng_guj: 'rutu',
      hindi: 'ऋतु, मौसम',
      english: 'season, weather',
      hint: ''
    },
    ઋષિ: {
      eng_guj: 'rushi',
      hindi: 'ऋषि',
      english: 'sage, saint',
      hint: ''
    },
    ઋજુતા: {
      eng_guj: 'rujuta',
      hindi: 'सादगी, सरलता',
      english: 'simplicity, softness',
      hint: ''
    },
    ઋણાનુબંધ: {
      eng_guj: 'runaanubandh',
      hindi: 'ऋणानुबंध',
      english: 'bond of indebtedness contracted in previous births',
      hint: ''
    },
    ઋત્વિક: {
      eng_guj: 'rutvik',
      hindi: 'ऋत्विक',
      english: 'Rutvik (name)',
      hint: ''
    },
    ઋગ્વેદ: {
      eng_guj: 'rugved',
      hindi: 'ऋग्वेद',
      english: 'one of the four Vedas',
      hint: ''
    },
    કૃપા: {
      eng_guj: 'krupa',
      hindi: 'कृपा',
      english: 'kindness, favor',
      hint: ''
    },
    કૃષ્ણ: {
      eng_guj: 'krushan',
      hindi: 'कृष्ण',
      english: 'Lord Krishna',
      hint: ''
    },
    સંસ્કૃતિ: {
      eng_guj: 'sanskruti',
      hindi: 'संस्कृति',
      english: 'culture',
      hint: ''
    },
    પ્રકૃતિ: {
      eng_guj: 'prakruti',
      hindi: 'प्रकृति',
      english: 'nature',
      hint: ''
    },
    કૃતજ્ઞ: {
      eng_guj: 'krutagna',
      hindi: 'आभारी',
      english: 'grateful',
      hint: ''
    },
    આકૃતિ: {
      eng_guj: 'aakriti',
      hindi: 'आकृति',
      english: 'figure, design',
      hint: ''
    },
    કૃપાળુદેવ: {
      eng_guj: 'krupaalu dev',
      hindi: 'कृपालु देव',
      english: 'krupaalu dev',
      hint: ''
    },
    પૃથ્વી: {
      eng_guj: 'pruthvi',
      hindi: 'पृथ्वी',
      english: 'earth',
      hint: ''
    },
    પૃથક્કરણ: {
      eng_guj: 'pruthakaran',
      hindi: 'विश्लेषण',
      english: 'analysis',
      hint: ''
    },
    સૃષ્ટિ: {
      eng_guj: 'srushti',
      hindi: 'सृष्टि',
      english: 'the universe, nature',
      hint: ''
    },
    તૃપ્તિ: {
      eng_guj: 'trupti',
      hindi: 'तृप्ति',
      english: 'satisfaction',
      hint: ''
    },
    તૃષા: {
      eng_guj: 'trusha',
      hindi: 'प्यास, अभिलाषा',
      english: 'thirst, desire',
      hint: ''
    },
    તૃણ: {
      eng_guj: 'trun',
      hindi: 'घास',
      english: 'grass',
      hint: ''
    },
    તૃતીય: {
      eng_guj: 'truteeya',
      hindi: 'तृतीय, तीसरा',
      english: 'third',
      hint: ''
    },
    વિસ્તૃત: {
      eng_guj: 'vistrut',
      hindi: 'विस्तृत',
      english: 'extended',
      hint: ''
    },
    નેતૃત્વ: {
      eng_guj: 'netrutav',
      hindi: 'नेतृत्व',
      english: 'leadership',
      hint: ''
    },
    હૃદય: {
      eng_guj: 'hruday',
      hindi: 'हृदय',
      english: 'heart',
      hint: ''
    },
    હૃષ્ટપુષ્ટ: {
      eng_guj: 'hrushtpusht',
      hindi: 'हृष्टपुष्ट',
      english: 'strong',
      hint: ''
    },
    હૃષીકેશ: {
      eng_guj: 'hrushikesh',
      hindi: 'ऋषिकेश',
      english: 'Rishikesh (a city in India)',
      hint: ''
    },
    દૃષ્ટિ: {
      eng_guj: 'dhrashti',
      hindi: 'दृष्टि',
      english: 'vision',
      hint: ''
    },
    દૃઢ: {
      eng_guj: 'dhrad',
      hindi: 'दृढ',
      english: 'firm',
      hint: ''
    },
    આમ્રપાલી: {
      eng_guj: 'Aamrapaali',
      hindi: 'आम्रपाली (नाम)',
      english: 'a name',
      hint: ''
    },
    નમ્ર: {
      eng_guj: 'namra',
      hindi: 'नम्र',
      english: 'humble, polite',
      hint: ''
    },
    ભ્રાંતિ: {
      eng_guj: 'bhraanti',
      hindi: 'भ्रांति',
      english: 'illusion',
      hint: ''
    },
    ભ્રમર: {
      eng_guj: 'bhramar',
      hindi: 'भौहें',
      english: 'eyebrow',
      hint: ''
    },
    ભ્રાતૃભાવ: {
      eng_guj: 'bhraatrubhaav',
      hindi: 'भाईचारा',
      english: 'brotherhood',
      hint: ''
    },
    પ્રદેશ: {
      eng_guj: 'Pradesh',
      hindi: 'प्रदेश, क्षेत्र',
      english: 'region',
      hint: ''
    },
    પ્રત્યેક: {
      eng_guj: 'pratyek',
      hindi: 'प्रत्येक',
      english: 'each',
      hint: ''
    },
    પ્રતીક: {
      eng_guj: 'prateek',
      hindi: 'प्रतीक',
      english: 'symbol',
      hint: ''
    },
    પ્રેમ: {
      eng_guj: 'prem',
      hindi: 'प्रेम',
      english: 'love',
      hint: ''
    },
    પ્રોમીસ: {
      eng_guj: 'promise',
      hindi: 'प्रोमीस',
      english: 'promise',
      hint: ''
    },
    પ્રત્યક્ષ: {
      eng_guj: 'pratyaksh',
      hindi: 'प्रत्यक्ष',
      english: 'present',
      hint: ''
    },
    પ્રગટ: {
      eng_guj: 'pragat',
      hindi: 'प्रगट',
      english: 'manifest',
      hint: ''
    },
    પ્રણામ: {
      eng_guj: 'pranaam',
      hindi: 'प्रणाम',
      english: 'salutation',
      hint: ''
    },
    પ્રદર્શિત: {
      eng_guj: 'pradarshit',
      hindi: 'प्रदर्शित',
      english: 'displayed',
      hint: ''
    },
    પ્રવૃત્તિ: {
      eng_guj: 'pravrutti',
      hindi: 'गतिविधि',
      english: 'activity',
      hint: ''
    },
    ક્રમ: {
      eng_guj: 'kram',
      hindi: 'क्रम',
      english: 'order, sequence',
      hint: ''
    },
    ક્રમિક: {
      eng_guj: 'kramik',
      hindi: 'क्रमिक',
      english: 'progressive, successive',
      hint: ''
    },
    અક્રમ: {
      eng_guj: 'akram',
      hindi: 'अक्रम',
      english: 'step-less',
      hint: ''
    },
    ક્રમશઃ: {
      eng_guj: 'kramsh',
      hindi: 'क्रमशः, बारी बारी',
      english: 'sequential order',
      hint: ''
    },
    ક્રાંતિ: {
      eng_guj: 'kraanti',
      hindi: 'क्रांति',
      english: 'revolution',
      hint: ''
    },
    ચક્ર: {
      eng_guj: 'chakra',
      hindi: 'चक्र',
      english: 'cycle',
      hint: ''
    },
    સક્રિય: {
      eng_guj: 'sakriy',
      hindi: 'सक्रिय',
      english: 'active',
      hint: ''
    },
    કાર્યક્રમ: {
      eng_guj: 'kaaraykram',
      hindi: 'कार्यक्रम',
      english: 'program',
      hint: ''
    },
    પ્રક્રિયા: {
      eng_guj: 'prakriyaa',
      hindi: 'प्रक्रिया',
      english: 'procedure, process',
      hint: ''
    },
    અનુક્રમણિકા: {
      eng_guj: 'anukramanika',
      hindi: 'सूची',
      english: 'list',
      hint: ''
    },
    ટ્રામ: {
      eng_guj: 'tram',
      hindi: 'ट्राम',
      english: 'streetcar',
      hint: ''
    },
    રાષ્ટ્ર: {
      eng_guj: 'rashtra',
      hindi: 'राष्ट्र',
      english: 'nation',
      hint: ''
    },
    ટ્રાફિક: {
      eng_guj: 'traafic',
      hindi: 'ट्रैफ़िक',
      english: 'traffic',
      hint: ''
    },
    ટ્રેન: {
      eng_guj: 'train',
      hindi: 'रेल गाडी',
      english: 'train',
      hint: ''
    },
    મહારાષ્ટ્ર: {
      eng_guj: 'Maharashtra',
      hindi: 'महाराष्ट्र',
      english: 'an Indian state',
      hint: ''
    },
    સૌરાષ્ટ્ર: {
      eng_guj: 'sauraashtra',
      hindi: 'सौराष्ट्र',
      english: 'a region in Gujarat',
      hint: ''
    },
    અર્ક: {
      eng_guj: 'arak',
      hindi: 'सार, निष्कर्ष',
      english: 'extract',
      hint: ''
    },
    તર્ક: {
      eng_guj: 'tarak',
      hindi: 'तर्क',
      english: 'reasoning',
      hint: ''
    },
    વિતર્ક: {
      eng_guj: 'vitarak',
      hindi: 'बहस',
      english: 'argument',
      hint: ''
    },
    સંપર્ક: {
      eng_guj: 'sampark',
      hindi: 'संपर्क',
      english: 'contact',
      hint: ''
    },
    સતર્ક: {
      eng_guj: 'satark',
      hindi: 'जागरूक',
      english: 'alert, vigilant',
      hint: ''
    },
    ધર્મ: {
      eng_guj: 'dharam',
      hindi: 'धर्म',
      english: 'religion',
      hint: ''
    },
    કર્મ: {
      eng_guj: 'karam',
      hindi: 'कर्म',
      english: 'deed',
      hint: ''
    },
    નિર્મમ: {
      eng_guj: 'nirmam',
      hindi: 'क्रूर',
      english: 'cruel',
      hint: ''
    },
    સત્ય: {
      eng_guj: 'satya',
      hindi: 'सत्य',
      english: 'truth',
      hint: ''
    },
    ત્યાગ: {
      eng_guj: 'tyaag',
      hindi: 'त्याग, बलिदान',
      english: 'sacrifice',
      hint: ''
    },
    ત્યાં: {
      eng_guj: 'tyan',
      hindi: 'वहाँ',
      english: 'there',
      hint: ''
    },
    નિત્યક્રમ: {
      eng_guj: 'nityakram',
      hindi: 'दिनचर्या',
      english: 'routine',
      hint: ''
    },
    અત્યંત: {
      eng_guj: 'atyant',
      hindi: 'अत्यधिक',
      english: 'extreme',
      hint: ''
    },
    જીત્યું: {
      eng_guj: 'jeetayu',
      hindi: 'जीत लिया',
      english: 'won',
      hint: ''
    },
    સાહિત્ય: {
      eng_guj: 'saahitaya',
      hindi: 'साहित्य',
      english: 'literature',
      hint: ''
    },
    અગત્ય: {
      eng_guj: 'agatya',
      hindi: 'ज़रूरी',
      english: 'necessary, important',
      hint: ''
    },
    સ્વયં: {
      eng_guj: 'swayam',
      hindi: 'स्वयं',
      english: 'spontaneously',
      hint: ''
    },
    સ્વતંત્ર: {
      eng_guj: 'swatantra',
      hindi: 'आज़ाद',
      english: 'independent',
      hint: ''
    },
    સ્વાર્થ: {
      eng_guj: 'swaarath',
      hindi: 'स्वार्थ',
      english: 'selfishness',
      hint: ''
    },
    સ્વસ્થ: {
      eng_guj: 'swasath',
      hindi: 'स्वस्थ',
      english: 'healthy',
      hint: ''
    },
    સ્વામી: {
      eng_guj: 'swami',
      hindi: 'स्वामी',
      english: 'lord',
      hint: ''
    },
    સ્વભાવ: {
      eng_guj: 'swabhaav',
      hindi: 'स्वभाव',
      english: 'nature',
      hint: ''
    },
    સ્વાગત: {
      eng_guj: 'swaagat',
      hindi: 'स्वागत',
      english: 'welcome',
      hint: ''
    },
    સ્વપ્ન: {
      eng_guj: 'swapan',
      hindi: 'सपना',
      english: 'dream',
      hint: ''
    },
    સ્વીકાર: {
      eng_guj: 'sweekaar',
      hindi: 'स्वीकार',
      english: 'accept',
      hint: ''
    },
    સ્ત્રી: {
      eng_guj: 'stree',
      hindi: 'स्त्री',
      english: 'woman, female',
      hint: ''
    },
    ઈસ્ત્રી: {
      eng_guj: 'eestree',
      hindi: 'इस्त्री',
      english: 'ironing',
      hint: ''
    },
    શસ્ત્ર: {
      eng_guj: 'Shastra',
      hindi: 'शस्त्र',
      english: 'weapon',
      hint: ''
    },
    શાસ્ત્ર: {
      eng_guj: 'shaastra',
      hindi: 'शास्त्र',
      english: 'scripture',
      hint: ''
    },
    સ્ત્રોત: {
      eng_guj: 'strot',
      hindi: 'स्त्रोत, साधन',
      english: 'source',
      hint: ''
    },
    સહસ્ત્ર: {
      eng_guj: 'sahastra',
      hindi: 'हजारों',
      english: 'thousands',
      hint: ''
    },
    વસ્ત્ર: {
      eng_guj: 'vastra',
      hindi: 'कपड़े',
      english: 'clothes',
      hint: ''
    },
    મલ્લ: {
      eng_guj: 'mall',
      hindi: 'मल्ल',
      english: 'athlete',
      hint: ''
    },
    મલ્લિનાથ: {
      eng_guj: 'mallinaath',
      hindi: 'मल्लिनाथ भगवान',
      english: 'Lord Mallinath',
      hint: ''
    },
    ઉલ્લેખ: {
      eng_guj: 'ullekh',
      hindi: 'उल्लेख',
      english: 'mention, reference',
      hint: ''
    },
    છેલ્લા: {
      eng_guj: 'chhella',
      hindi: 'अंतिम',
      english: 'last, ultimate',
      hint: ''
    },
    અત્તર: {
      eng_guj: 'attar',
      hindi: 'इत्र',
      english: 'perfume',
      hint: ''
    },
    સત્તા: {
      eng_guj: 'sattaa',
      hindi: 'शक्ति',
      english: 'authority, power',
      hint: ''
    },
    વૃત્તિ: {
      eng_guj: 'vrutti',
      hindi: 'वृत्ति',
      english: 'instinct',
      hint: ''
    },
    સંપત્તિ: {
      eng_guj: 'sampatti',
      hindi: 'संपत्ति',
      english: 'property, wealth',
      hint: ''
    },
    મહત્તા: {
      eng_guj: 'mahatta',
      hindi: 'महानता',
      english: 'greatness',
      hint: ''
    },
    ઉત્તેજના: {
      eng_guj: 'uttejnaa',
      hindi: 'उत्साह',
      english: 'excitement',
      hint: ''
    },
    ઉત્તમ: {
      eng_guj: 'uttam',
      hindi: 'उत्कृष्ट',
      english: 'excellent',
      hint: ''
    },
    ઉત્તર: {
      eng_guj: 'uttar',
      hindi: 'उत्तर दिशा',
      english: 'north direction',
      hint: ''
    },
    અગત્સ્ય: {
      eng_guj: 'agatsaya',
      hindi: 'अगत्स्य',
      english: 'name of a Rishi',
      hint: ''
    },
    મત્સ્યગંધા: {
      eng_guj: 'matsyagandha',
      hindi: 'मत्स्यगंधा',
      english: 'matsyagandha',
      hint: ''
    },
    ક્ષમા: {
      eng_guj: 'kshamaa',
      hindi: 'माफी',
      english: 'forgiveness',
      hint: ''
    },
    ક્ષતિ: {
      eng_guj: 'kshati',
      hindi: 'क्षति, नुकसान',
      english: 'loss',
      hint: ''
    },
    રક્ષા: {
      eng_guj: 'raksha',
      hindi: 'रक्षा, बचाव',
      english: 'protection',
      hint: ''
    },
    ક્ષણ: {
      eng_guj: 'kshan',
      hindi: 'पल',
      english: 'moment, instant',
      hint: ''
    },
    મોક્ષ: {
      eng_guj: 'moksh',
      hindi: 'मोक्ष',
      english: 'salvation',
      hint: ''
    },
    ક્ષીણમોહ: {
      eng_guj: 'ksheenmoh',
      hindi: 'क्षीणमोह',
      english: 'vitaraag state',
      hint: ''
    },
    લક્ષણ: {
      eng_guj: 'lakshan',
      hindi: 'लक्षण',
      english: 'symptom',
      hint: ''
    },
    ક્ષેત્ર: {
      eng_guj: 'kshetra',
      hindi: 'क्षेत्र',
      english: 'region',
      hint: ''
    },
    નિષ્પક્ષપાતી: {
      eng_guj: 'nishpakshpaati',
      hindi: 'निष्पक्षपाती',
      english: 'Impartial; non-sectarian',
      hint: ''
    },
    દિવ્યચક્ષુ: {
      eng_guj: 'divya chakshu',
      hindi: 'दिव्य चक्षु',
      english: 'divine vision',
      hint: ''
    },
    સાક્ષાત્કાર: {
      eng_guj: 'saakshaatkaar',
      hindi: 'साक्षात्कार',
      english: 'realization',
      hint: ''
    },
    જ્ઞાન: {
      eng_guj: 'gnaan',
      hindi: 'ज्ञान, जानना',
      english: 'knowledge',
      hint: ''
    },
    જ્ઞાની: {
      eng_guj: 'gnaani',
      hindi: 'ज्ञानी',
      english: 'the one with the knowledge of self',
      hint: ''
    },
    આજ્ઞા: {
      eng_guj: 'aagnaa',
      hindi: 'आज्ञा',
      english: "dada's five Aagna ",
      hint: ''
    },
    વિજ્ઞાન: {
      eng_guj: 'vignaan',
      hindi: 'विज्ञान',
      english: 'science',
      hint: ''
    },
    સર્વજ્ઞ: {
      eng_guj: 'sarvagna',
      hindi: 'सर्वज्ञ',
      english: 'omniscient',
      hint: ''
    },
    શુદ્ધ: {
      eng_guj: 'shudh',
      hindi: 'शुद्ध',
      english: 'pure',
      hint: ''
    },
    શુદ્ધાત્મા: {
      eng_guj: 'shudhaatmaa',
      hindi: 'शुद्धात्मा',
      english: 'pure soul',
      hint: ''
    },
    ઉદ્ધાર: {
      eng_guj: 'udhaar',
      hindi: 'उद्धार',
      english: 'uplift, elevation',
      hint: ''
    },
    પદ્ધતિ: {
      eng_guj: 'padhati',
      hindi: 'पद्धति',
      english: 'ritual',
      hint: ''
    },
    બુદ્ધિ: {
      eng_guj: 'budhi',
      hindi: 'बुद्धि',
      english: 'intellect',
      hint: ''
    },
    સિદ્ધાંત: {
      eng_guj: 'sidhaant',
      hindi: 'सिद्धांत',
      english: 'principle',
      hint: ''
    },
    સમૃદ્ધ: {
      eng_guj: 'smrudh',
      hindi: 'समृद्ध, धनी',
      english: 'rich',
      hint: ''
    },
    વૃદ્ધાવસ્થા: {
      eng_guj: 'vrudhaavasthaa',
      hindi: 'बुढ़ापा',
      english: 'old age',
      hint: ''
    },
    બુદ્ધ: {
      eng_guj: 'buddh',
      hindi: 'बुद्ध',
      english: 'the lord Buddha',
      hint: ''
    },
    વિરુદ્ધ: {
      eng_guj: 'viruddh',
      hindi: 'विरुद्ध',
      english: 'opposite',
      hint: ''
    },
    યુદ્ધ: {
      eng_guj: 'yuddh',
      hindi: 'युद्ध',
      english: 'war',
      hint: ''
    },
    અધ્ધર: {
      eng_guj: 'addhar',
      hindi: 'अनिश्चित',
      english: 'undecided',
      hint: ''
    },
    સધ્ધર: {
      eng_guj: 'saddhar',
      hindi: 'संपन्न',
      english: 'wealthy',
      hint: ''
    },
    પદ્મ: {
      eng_guj: 'padam',
      hindi: 'पद्म',
      english: 'lotus',
      hint: ''
    },
    પદ્માસન: {
      eng_guj: 'padamaasan',
      hindi: 'पद्मासन',
      english: 'lotus pose',
      hint: ''
    },
    દ્વારા: {
      eng_guj: 'dwaraa',
      hindi: 'द्वारा',
      english: 'through, by',
      hint: ''
    },
    દ્વાર: {
      eng_guj: 'dwar',
      hindi: 'द्वार',
      english: 'gate',
      hint: ''
    },
    દ્વેષ: {
      eng_guj: 'dwesh',
      hindi: 'द्वेष',
      english: 'dislike, hatred',
      hint: ''
    },
    દ્વારકા: {
      eng_guj: 'Dwaarka',
      hindi: 'द्वारका',
      english: 'capital city of Krishna',
      hint: ''
    },
    દ્વીપ: {
      eng_guj: 'dweep',
      hindi: 'द्वीप',
      english: 'island',
      hint: ''
    },
    મુદ્રા: {
      eng_guj: 'mudra',
      hindi: 'आसन',
      english: 'posture',
      hint: ''
    },
    ચંદ્ર: {
      eng_guj: 'Chandra',
      hindi: 'चांद',
      english: 'moon',
      hint: ''
    },
    ઉપદ્રવ: {
      eng_guj: 'updrav',
      hindi: 'नुकसान',
      english: 'harm',
      hint: ''
    },
    દ્રશ્ય: {
      eng_guj: 'drashya',
      hindi: 'दृश्य',
      english: 'visual, scene',
      hint: ''
    },
    દ્રષ્ટિ: {
      eng_guj: 'drashti',
      hindi: 'दृष्टि',
      english: 'vision, sight',
      hint: ''
    },
    દ્રવ્ય: {
      eng_guj: 'dravya',
      hindi: 'द्रव्य',
      english: 'matter',
      hint: ''
    },
    દ્રાક્ષ: {
      eng_guj: 'draaksh',
      hindi: 'अंगूर',
      english: 'grapes',
      hint: ''
    },
    વિદ્યા: {
      eng_guj: 'vidya',
      hindi: 'विद्या',
      english: 'knowledge, learning',
      hint: ''
    },
    વિદ્યાર્થી: {
      eng_guj: 'vidyaarthi',
      hindi: 'विद्यार्थी',
      english: 'student',
      hint: ''
    },
    નૈવેદ્ય: {
      eng_guj: 'naivedya',
      hindi: 'प्रस्ताव',
      english: 'offering',
      hint: ''
    },
    ઔદ્યોગિક: {
      eng_guj: 'audayogik',
      hindi: 'औद्योगिक',
      english: 'industrial',
      hint: ''
    },
    ઉદ્યોગ: {
      eng_guj: 'udyog',
      hindi: 'उद्योग',
      english: 'industry',
      hint: ''
    },
    વિદ્યાભ્યાસ: {
      eng_guj: 'vidyabhyaas',
      hindi: 'शिक्षा',
      english: 'education',
      hint: ''
    },
    પરમેશ્વર: {
      eng_guj: 'parmeshwar',
      hindi: 'परमेश्वर',
      english: 'god',
      hint: ''
    },
    ઈશ્વર: {
      eng_guj: 'eeshwar',
      hindi: 'भगवान',
      english: 'god',
      hint: ''
    },
    અશ્વ: {
      eng_guj: 'ashv',
      hindi: 'घोड़ा',
      english: 'horse',
      hint: ''
    },
    વિશ્વ: {
      eng_guj: 'vishav',
      hindi: 'विश्व, संसार',
      english: 'world',
      hint: ''
    },
    શાશ્વત: {
      eng_guj: 'shaashwat',
      hindi: 'शाश्वत',
      english: 'eternal',
      hint: ''
    },
    શ્વાસ: {
      eng_guj: 'shwaas',
      hindi: 'सांस',
      english: 'breath',
      hint: ''
    },
    શ્વાન: {
      eng_guj: 'shvaan',
      hindi: 'कुत्ता',
      english: 'dog',
      hint: ''
    },
    પાર્શ્વનાથ: {
      eng_guj: 'paarshwanaath',
      hindi: 'भगवान पार्श्वनाथ',
      english: 'Lord Paarshwanaath',
      hint: ''
    },
    અરુણાચલેશ્વર: {
      eng_guj: 'arunaachaleshwar',
      hindi: 'अरुणाचलेश्वर',
      english: 'name of place in India',
      hint: ''
    },
    ગુહ્ય: {
      eng_guj: 'guhya',
      hindi: 'गहरा',
      english: 'deep',
      hint: ''
    },
    બાહ્ય: {
      eng_guj: 'bahya',
      hindi: 'बाहरी',
      english: 'external',
      hint: ''
    },
    અસહ્ય: {
      eng_guj: 'asahya',
      hindi: 'असहनीय',
      english: 'unbearable',
      hint: ''
    },
    રહ્યા: {
      eng_guj: 'rahya',
      hindi: 'रहा',
      english: 'remained',
      hint: ''
    },
    બ્રહ્માંડ: {
      eng_guj: 'brahamaand',
      hindi: 'ब्रह्मांड',
      english: 'universe',
      hint: ''
    },
    બ્રહ્મ: {
      eng_guj: 'braham',
      hindi: 'ब्रह्म',
      english: 'The Self; Atma; God',
      hint: ''
    },
    બ્રાહ્મણ: {
      eng_guj: 'braahaman',
      hindi: 'ब्राह्मण',
      english: 'Brahmin',
      hint: ''
    },
    નિશ્ચય: {
      eng_guj: 'nishchay',
      hindi: 'निश्चय',
      english: 'determination',
      hint: ''
    },
    નિશ્ચિત: {
      eng_guj: 'nishchit',
      hindi: 'निश्चित',
      english: 'decided, fixed',
      hint: ''
    },
    પશ્ચિમ: {
      eng_guj: 'pashchim',
      hindi: 'पश्चिम दिशा',
      english: 'West direction',
      hint: ''
    },
    ઉત્પન્ન: {
      eng_guj: 'utpann',
      hindi: 'उत्पन्न',
      english: 'generated',
      hint: ''
    },
    પ્રસન્ન: {
      eng_guj: 'prasann',
      hindi: 'प्रसन्न',
      english: 'glad, happy',
      hint: ''
    },
    વિભિન્ન: {
      eng_guj: 'vibhinn',
      hindi: 'विभिन्न',
      english: 'different',
      hint: ''
    },
    ઉન્નત: {
      eng_guj: 'unnat',
      hindi: 'उन्नत',
      english: 'advanced',
      hint: ''
    },
    અન્ન: {
      eng_guj: 'ann',
      hindi: 'अनाज',
      english: 'food',
      hint: ''
    }
  }

}

export default wordList
