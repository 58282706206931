import Vue from 'vue'
import VueI18n from 'vue-i18n'
import wordList from './store/data'
import categories from './store/PracticePlayCategories'
Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  loadWordsIntoLocale(messages)
  loadCategories(messages)
  return messages
}

function loadWordsIntoLocale(messages) {
  Object.keys(wordList).forEach((key) => {
    messages.gu[key + 'words'] = {}
    messages.hi[key + 'words'] = {}
    messages.en[key + 'words'] = {}
    Object.keys(wordList[key]).forEach((k, i) => {
      messages.gu[key + 'words'][i] = k
      messages.en[key + 'words'][i] = wordList[key][k].english
      messages.hi[key + 'words'][i] = wordList[key][k].hindi
    })
  })
}

function loadCategories(messages) {
  categories.forEach((c) => {
    messages.gu[c.id] = c.name.gu
    messages.hi[c.id] = c.name.hi
    messages.en[c.id] = c.name.en
  })
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
