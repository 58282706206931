<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          plain
          v-bind="attrs"
          v-on="on"
          class="langSelectionButton"
          :style="{ fontSize: $vuetify.breakpoint.mobile ? '15px' : '20px' }"
        >
          {{ langs[selectedLang].selectedDisplay || langs[selectedLang].text }}
          <v-icon right color="primary" class="ml-0 langSelectionButton"
            >mdi-chevron-down</v-icon
          >
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in Object.keys(langs).filter(
            (l) => l !== selectedLang
          )"
          :key="index"
          @click="changeLocale(item)"
        >
          <v-list-item-title class="langItem">{{
            langs[item].text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <AreYouSure
      :visible="areYouSureDialog"
      cardTitle="Set Default Language?"
      :cardText="areYouSureText"
      noBtnTxt="Skip For Now"
      @areYouSure="areYouSure"
      @close="areYouSureDialog = false"
    >
    </AreYouSure>
  </div>
</template>
<script>
import AreYouSure from './AreYouSure'
import { mapGetters } from 'vuex'

export default {
  components: {
    AreYouSure
  },
  props: [],
  data: () => ({
    selectedLang: 'en',
    areYouSureDialog: false
  }),
  computed: {
    ...mapGetters(['langs']),

    locale() {
      return this.$i18n.locale
    },
    areYouSureText() {
      return `Do you want to set <b>${
        this.langs[this.selectedLang].text
      }</b> as your default preferred language? <br> If selected 'Yes', this website will open in <b>${
        this.langs[this.selectedLang].text
      }</b> by default whenever you come back again. <br><br> <b>Note:</b> You can change default language again anytime from this menu.`
    }
  },
  mounted() {
    if (localStorage.getItem('locale')) {
      this.$i18n.locale = localStorage.getItem('locale')
    } else {
      localStorage.setItem('locale', this.$i18n.locale)
    }
    this.selectedLang = this.$i18n.locale
  },
  methods: {
    changeLocale(lang) {
      this.$store.state.selectedLanguage = this.langs[lang].desc
      this.$i18n.locale = lang
      this.selectedLang = lang
      this.areYouSureDialog = true
    },
    setLangPreference() {
      localStorage.setItem('locale', this.$i18n.locale)
    },
    areYouSure(answer) {
      if (answer) {
        this.setLangPreference()
      }
      this.areYouSureDialog = false
    }
  },
  watch: {
    locale: function () {
      this.selectedLang = this.locale
    }
  }
}
</script>
<style scoped>
.langSelectionButton >>> .v-btn__content {
  opacity: 1 !important;
}
.langItem {
  line-height: 1.5 !important;
}
</style>
